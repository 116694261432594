import React from "react";
import {Link, useParams} from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import NotFound from "./NotFound";
import LearnPathBlock from "../components/LearnPathBlock";
import FullPageSpinner from "../components/FullPageSpinner";
import Title from "../components/Title";

/**
 * Jobs Page
 */

const GET_PAGE_QUERY = gql`
  query GetLessonPath($uri: ID!) {
    lessonpath(id: $uri, idType: URI) {
      id
      databaseId
      title
      content
      date
      uri
      acfFields {
        fieldGroupName
        titleIcon {
            sourceUrl
        }
        blocks {
          blockTitle
          steps {
            stepTitle
            stepNumber
            stepInfoText
            buttonTitle
            buttonLink
            bullets {
              bullet
            }
          }
          textBelowBlock
        }
        buttonForwardText
        buttonForwardLink
        buttonBackText
        buttonBackLink
      }
    }
  }
`;

export default function LearnPathPage() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_PAGE_QUERY, {
    variables: { uri: slug },
  }); /**/

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;

  if (!data.lessonpath) {
    return <NotFound />;
  }

  let button = "";
  let backButton = "";

  if (data.lessonpath.acfFields.buttonForwardLink && data.lessonpath.acfFields.buttonForwardText) {
    button = /^https?:\/\//.test(data.lessonpath.acfFields.buttonForwardLink) ? (
        <a
            href={data.lessonpath.acfFields.buttonForwardLink}
            target="_blank"
            rel="noopener noreferrer"
        >
          <button className="jobButtonRight  px-4 px-md-5">{data.lessonpath.acfFields.buttonForwardText}</button>
        </a>

    ) : (
        <Link to={data.lessonpath.acfFields.buttonForwardLink}>
          <button className="jobButtonRight  px-4 px-md-5">{data.lessonpath.acfFields.buttonForwardText}</button>
        </Link>

    );
  }

  if (data.lessonpath.acfFields.buttonBackLink && data.lessonpath.acfFields.buttonBackText) {
    backButton = /^https?:\/\//.test(data.lessonpath.acfFields.buttonBackLink) ? (
        <a
            href={data.lessonpath.acfFields.buttonBackLink}
            target="_blank"
            rel="noopener noreferrer"
        >
          <button className="jobButtonLeft  px-4 px-md-5">{data.lessonpath.acfFields.buttonBackText}</button>
        </a>
    ) : (
        <Link to={data.lessonpath.acfFields.buttonBackLink}>
          <button className="jobButtonLeft px-4 px-md-5">{data.lessonpath.acfFields.buttonBackText}</button>
        </Link>
    );
  }
  /*
  let button = "";

  if(data.job.acfFields.buttonLink && data.job.acfFields.buttonText) {
        button = (<div className="col-12 mt-5 text-center">
            <a href={data.job.acfFields.buttonLink} target="_blank" rel="noopener noreferrer">
                <button className="jobButton">{data.job.acfFields.buttonText}</button>
            </a>
        </div>);
    }
    */

  return (
    <div className="learnPath container-fluid m-0">
        <Title pageTitle={data.lessonpath.title}/>
      <div className="row">
        <div className="headerImage col-12 p-0"></div>
      </div>
      <div className="row bg-yellow angle learnPathHeader">
        <div className="col-12">
          <div className="container">
            <div className="row">
                <div className="col-10">
                    <h1>{data.lessonpath.title}</h1>
                </div>
                <div className="col-2">
                    {data.lessonpath.acfFields.titleIcon && (<img className="titleImage" src={data.lessonpath.acfFields.titleIcon.sourceUrl}/>)}
                </div>
              <div
                className="col-12 pt-5 pb-5"
                dangerouslySetInnerHTML={{ __html: data.lessonpath.content }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-5">
          <div className="container learnPathContainer">
            <div className="row">
              {data.lessonpath.acfFields.blocks.map((item, key) => (
                <LearnPathBlock key={key} theKey={key} content={item} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 mt-5 text-center text-md-left">
                {backButton}
              </div>
              <div className="col-12 col-md-6 mt-5 text-center text-md-right">
                {button}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
