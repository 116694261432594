import React, {useState} from "react";
import {useQuery, gql, useMutation} from "@apollo/client";
import {API_URL, AUTH_TOKEN} from "../env";
import { Form, Button, Alert } from 'react-bootstrap';
import EventBlock from "../components/EventBlock";
import {Link} from "react-router-dom";
//import gql from "graphql-tag/src";
import axios from 'axios';

/**
 * Home Page
 */
const LOGIN_USER = gql`
  mutation LoginUser($username: String! $password: String!) {
    login(input: {
      clientMutationId: "uniqueId"
      username: $username
      password: $password
    }) {
      authToken
      user {
        id
        userId
        name
        firstName
        lastName
        username
        description
        userFields {
            fieldGroupName
            profieldata
            nieuwsbrief
            adres
            postcode
            woonplaats
            telefoonnummer
        }
      }
    }
  }
`

const UPDATE_USER = gql`
mutation UpdateUser($id: ID! $firstname: String! $lastname: String! $adres: String! $postcode: String! $woonplaats: String! $telefoonnummer: String!) {
    updateUser(input: {
      id: $id,
      firstName: $firstname
      lastName: $lastname
      adres: $adres
      postcode: $postcode
      woonplaats: $woonplaats
      telefoonnummer: $telefoonnummer
    }) {
      user {
        id
      }
    }
  }
`

const UPDATE_USER_PROFILE = gql`
mutation UpdateUser($id: ID! $profiledata: String! $nieuwsbrief: Int!) {
    updateUser(input: {
      id: $id,
      profiledata: $profiledata,
      nieuwsbrief: $nieuwsbrief
    }) {
      user {
        id
        userFields {
            fieldGroupName
            profieldata
            adres
            postcode
            woonplaats
            telefoonnummer
        }
      }
    }
  }
`

export default function LoginPage() {


    const token = localStorage.getItem(AUTH_TOKEN);
    let userJson = false;

    try {
        userJson = JSON.parse(token);
    } catch (e) {};

    let userData = window.userdata;
    let profileData = {};

    let nieuwsbrief = userData?.userFields?.nieuwsbrief;

    console.log(nieuwsbrief);

    try {
        profileData = JSON.parse(userData?.userFields?.profieldata);

    } catch (e) {
        profileData = {};
    }

    const [state, setState] = useState({
        validate: false,
        username: '',
        password: '',
        error: '',
        editUserdata:false,
        firstName:userData?.firstName,
        lastName:userData?.lastName,
        email:userData?.username,
        adres:userData?.userFields?.adres,
        postcode:userData?.userFields?.postcode,
        woonplaats:userData?.userFields?.woonplaats,
        telefoonnummer:userData?.userFields?.telefoonnummer,
        selectedFile: null,
        cvSelectedFile: null,
        selectedPaths:[],
        currentFunction:'',
        ambitionFunction:'',
        profileData:profileData,
        editFunctionData: false,
        editPathsData: false,
        newsletter: nieuwsbrief

    });

    //if (login) return <p>Loading...</p>;
    //if (error) return <p>Error :(</p>;

    const [login, {data}] = useMutation(LOGIN_USER);


    const pathTypes = [
        "Customer service specialist",
        "Data analyst",
        "Digital marketing specialist",
        "Financial analyst",
        "Graphic designer",
        "Microsoft 365 teamwork administrator",
        "Microsoft azure administrator",
        "Microsoft 365 security administrator",
        "IT help desk technician",
        "Project manager",
        "Sales representative",
        "Azure developer",
        "Microsoft 365 developer",
        "Azure security engineer",
        "Power platform"
    ];

    const[updateUser, {updateData}] = useMutation(UPDATE_USER);

    const[updateProfile, {updateProfileData}] = useMutation(UPDATE_USER_PROFILE);

    function handleLogin(event) {
        event.preventDefault();


        const {username, password} = state;

        //setState({ validate: true });

//        console.log(username);

        login({ variables: { username, password }})
            .then(response => handleLoginSuccess(response))
            .catch(err => handleLoginFail(err.graphQLErrors[0].message));
    }

    function handleLoginSuccess(response) {
        let data = {authToken: response.data.login.authToken, user: {firstName:response.data.login.user.firstName, lastName: response.data.login.user.lastName, name: response.data.login.user.name}};

        localStorage.setItem(AUTH_TOKEN, JSON.stringify(data));

        //console.log(response);
            setState({
                validate: false,
                username: '',
                password: '',
                error: '',

            });

            window.location.reload();
        //this.props.history.push('/profile');
    }

    function handleSaveSuccess(response) {

        window.location.reload();
        //this.props.history.push('/profile');
    }

    function handleLoginFail( err) {
        //console.log(err);
        const error = err.split('_').join(' ').toUpperCase();
        localStorage.removeItem(AUTH_TOKEN);
        setState(prevState => ({ ...prevState, error: error }));

    }

    function handleSaveFail( err) {
       // console.log(err);
        //const error = err.split('_').join(' ').toUpperCase();
        //localStorage.removeItem(AUTH_TOKEN);
        //setState(prevState => ({ ...prevState, error: error }));

    }

    function handleFirstnameChange(value)
    {
        setState(prevState => ({ ...prevState, firstName: value }));
    }

    function handleLastnameChange(value)
    {
        setState(prevState => ({ ...prevState, lastName: value }));
    }

    function handleUsername(username) {
        //console.log(username);
        setState(prevState => ({ ...prevState, username: username }));
    }

    function handlePassword(password) {
        setState(prevState => ({ ...prevState, password: password }));
    }

    function handleFieldChangeOnName(value, name) {
        setState(prevState => ({ ...prevState, [name]: value }));
    }

    function handleFieldChange(value, name)
    {
        //console.log(value, name);
        let p = state.profileData;
        if(p == null)
        {
            p = {};
        }

        p[name] = value;
        setState(prevState => ({ ...prevState, profileData: p }));
    }

    function handleCheckboxChange(value, name, checked)
    {
        //console.log(value, name, checked);
        let p = state.profileData;

        if(p == null)
        {
            p = {};
        }

        if(!p[name])
        {
            p[name] = [];
        }
        if(checked) {
            p[name].push(value);
        } else {
            console.log(p[name].indexOf(value));
            if(p[name].indexOf(value) > -1)
            {
                p[name].splice(p[name].indexOf(value),1);
            }
        }

        console.log(state.profileData);

        setState(prevState => ({ ...prevState, profileData: p }));
    }

    function renderMessage(loading, error) {
        if (error) {
            return (
                <div variant="danger">
                    {this.state.error}
                </div>
            )
        } else if (loading) {
            return (
                <div variant="primary">
                    Loading...
                </div>
            )
        }
    }

    function editUserData()
    {
        setState(prevState => ({ ...prevState, editUserdata: true }));
    }

    function editFunctionData()
    {
        setState(prevState => ({ ...prevState, editFunctionData: true }));
    }

    function editPaths()
    {
        setState(prevState => ({ ...prevState, editPathsData: true }));
    }

    function saveUserData()
    {
        //userJson.user.firstName = state.firstName;
        //userJson.user.lastName = state.lastName;

        const {firstName, lastName, adres, postcode, woonplaats, telefoonnummer} = state;

        updateUser({ variables: { id: window.userdata.id, firstname: firstName  , lastname: lastName, adres:adres, postcode:postcode, woonplaats:woonplaats, telefoonnummer:telefoonnummer  }})
            .then(response => handleSaveSuccess(response))
            .catch(err => handleSaveFail(err.graphQLErrors[0].message));

        setState(prevState => ({ ...prevState, editUserdata: false }));
    }

    function uploadFile()
    {
        //console.log(state.selectedFile);
        const fd = new FormData();
        fd.append('testresult', state.selectedFile, state.selectedFile.name);
        fd.append('user', state.email);
        fd.append('id', window.userdata.userId);

        axios.post(API_URL + '/upload.php', fd).then(response=>{window.location.reload()});
    }

    function fileSelect(target)
    {
        setState(prevState => ({ ...prevState, selectedFile: target.files[0] }));
        //console.log(state.selectedFile);
    }

    function fileDelete(type, file)
    {
        const fd = new FormData();
        fd.append('type', type);
        fd.append('file', file);
        fd.append('user', state.email);
        fd.append('id', window.userdata.userId);

        axios.post(API_URL + '/delete.php', fd).then(response=>{window.location.reload()});
    }

    function uploadCVFile()
    {
        //console.log(state.selectedFile);
        const fd = new FormData();
        fd.append('testresult', state.cvSelectedFile, state.cvSelectedFile.name);
        fd.append('user', state.email);
        fd.append('id', window.userdata.userId);

        axios.post(API_URL + '/uploadcv.php', fd).then(response=>{window.location.reload()});
    }

    function cvFileSelect(target)
    {
        setState(prevState => ({ ...prevState, cvSelectedFile: target.files[0] }));
        //console.log(state.selectedFile);
    }

    function getDate(time)
    {
        let a = new Date(time * 1000);

        let months = ['jan','feb','mar','apr','mei','jun','jul','aug','sep','okt','nov','dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate()<10?'0'+a.getDate():a.getDate();
        let hour = a.getHours()<10?'0'+a.getHours():a.getHours();
        let min = a.getMinutes()<10?'0'+a.getMinutes():a.getMinutes();
        //var sec = a.getSeconds();
        let d = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min;
        return d;
    }

    function updateProfileHandler()
    {
        updateProfile({ variables: { id: window.userdata.id, profiledata: JSON.stringify(state.profileData) , nieuwsbrief: state.newsletter?1:0}})
            .then(response => handleUpdate(response)

            )
            .catch(err => console.log(err.graphQLErrors[0].message));
    }

    function handleUpdate(response)
    {
        try {
            let j = JSON.parse(response.data.updateUser.user.userFields.profieldata);

            setState(prevState => ({...prevState, profileData: j}));
        } catch (e) {

        }

        setState(prevState => ({ ...prevState, editFunctionData: false }));
        setState(prevState => ({ ...prevState, editPathsData: false }));
    }
    if(userData)
    {
        return (
            <div className="tipsTricks container-fluid m-0">
                <div className="row">
                    <div className="headerImage col-12 p-0"></div>
                </div>
                <div className="row bg-purple angle tipsHeader">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 mb-5">
                                    <h2 className="text-white">Hallo<br/>{state.firstName},</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-lightblue text-white d-inline-block p-4 pl-0 font-weight-bold">Algemeen</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-cyan  profile-holder"

                                >
                                    {!state.editUserdata && (<><h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Voornaam</h4>
                                    <p>{state.firstName}</p>

                                    <h4 className="bg-cyan text-blue p-0 font-weight-bold">Achternaam</h4>
                                    <p>{state.lastName}</p>

                                    <h4 className="bg-cyan text-blue p-0 font-weight-bold">E-mailadres</h4>
                                    <p>{userData.username}</p>

                                        <h4 className="bg-cyan text-blue p-0 font-weight-bold">Adres</h4>
                                        <p>{state.adres}<br/>{state.postcode} {state.woonplaats}</p>

                                        <h4 className="bg-cyan text-blue p-0 font-weight-bold">Telefoonnummer</h4>
                                        <p>{state.telefoonnummer}</p>

                                    <button onClick={editUserData} className="main_button bg-yellow text-blue">Wijzigen</button></>)}

                                    {state.editUserdata && (<><h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Voornaam</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="firstName"

                                            onChange={event => handleFirstnameChange(event.target.value)}
                                            value={state.firstName}
                                            required
                                        />

                                        <h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Achternaam</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="lastName"


                                            onChange={event => handleLastnameChange(event.target.value)}
                                            value={state.lastName}
                                            required
                                        />

                                        <h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Straatnaam + huisnummer</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="adres"
                                            onChange={event => handleFieldChangeOnName(event.target.value, event.target.name)}
                                            value={state.adres}
                                            required
                                        />

                                        <h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Postcode</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="postcode"
                                            onChange={event => handleFieldChangeOnName(event.target.value, event.target.name)}
                                            value={state.postcode}
                                            required
                                        />

                                        <h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Woonplaats</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="woonplaats"
                                            onChange={event => handleFieldChangeOnName(event.target.value, event.target.name)}
                                            value={state.woonplaats}
                                            required
                                        />

                                        <h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Telefoonnummer</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="telefoonnummer"
                                            onChange={event => handleFieldChangeOnName(event.target.value, event.target.name)}
                                            value={state.telefoonnummer}
                                            required
                                        />

                                        <h4 className="bg-cyan text-blue p-0 font-weight-bold">E-mailadres</h4>
                                        <p>{userData.username}</p>

                                        <button onClick={saveUserData} className="main_button bg-yellow text-blue">Opslaan</button></>)}
                                </div>


                            </div>

                            <div className="row mt-5">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-lightblue text-white d-inline-block p-4 pl-0 font-weight-bold">Beroepentest</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-cyan profile-holder"

                                >
                                    <h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Hier kun je de uitslagen van je beroepentest uploaden en bewaren.</h4>
                                    {!state.profileData?.tests && (<p>Nog geen test gedaan? Klik <Link className="text-white"  to="/test">hier</Link> en doe de test en sla jouw uitslag op!</p>)}
                                    {state.profileData?.tests && (<p>Nog een test toevoegen? Klik <Link className="text-white" to="/test">hier</Link> en doe de test en sla jouw uitslag op!</p>)}
                                    <div>
                                        {state.profileData?.tests && (
                                            state.profileData.tests.map((item, key)=>(
                                                <p key={key}><a target="_blank" className="text-white" href={API_URL + "/results/" + item.result}>{getDate(item.time)}</a> - <a className="text-white" href="#" onClick={(event)=> {if(window.confirm('Weet je zeker dat je dit bestand wilt verwijderen?')) fileDelete("result",item.result)}}><i>verwijderen</i></a></p>
                                            ))
                                        )}
                                    </div>
                                    <input type="file" accept="application/pdf" onChange={(event)=> fileSelect(event.target)}/><br/>
                                    <br/>
                                    <button onClick={uploadFile} className="main_button bg-yellow text-blue">Upload PDF</button>

                                </div>
                            </div>

                            <div className=" row mt-5">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-lightblue text-white d-inline-block p-4 pl-0 font-weight-bold">Ambitie</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-cyan profile-holder"

                                >
                                    {!state.editFunctionData && (<><h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Huidige functie</h4>
                                    {!state.profileData?.currentFunction && (<p>Nog niet ingevoerd</p>)}
                                    {state.profileData?.currentFunction && (<p>{state.profileData?.currentFunction}</p>)}

                                    <h4 className="bg-cyan text-blue p-0 font-weight-bold">Gewenste functie</h4>
                                    {!state.profileData?.ambitionFunction && (<p>Nog niet ingevoerd</p>)}
                                    {state.profileData?.ambitionFunction && (<p>{state.profileData?.ambitionFunction}</p>)}

                                    <button onClick={editFunctionData} className="main_button bg-yellow text-blue">Wijzigen</button></>)}

                                    {state.editFunctionData && (<><h4 className="bg-cyan text-blue mt-4 p-0 font-weight-bold">Huidige functie</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="currentFunction"

                                            onChange={event => handleFieldChange(event.target.value, event.target.name)}
                                            value={state.profileData?.currentFunction}
                                            required
                                        />

                                        <h4 className="bg-cyan text-blue p-0 mt-2 font-weight-bold">Gewenste functie</h4>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="ambitionFunction"

                                            onChange={event => handleFieldChange(event.target.value, event.target.name)}
                                            value={state.profileData?.ambitionFunction}
                                            required
                                        />

                                        <button onClick={updateProfileHandler} className="main_button mt-1 bg-yellow text-blue">Opslaan</button></>)}
                                </div>
                            </div>



                            <div className="row mt-5">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-orange text-white d-inline-block p-4 pl-0 font-weight-bold">Leren</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-lila"

                                >
                                    <h4 className="bg-lila text-blue mt-4 p-0 font-weight-bold"></h4>
                                    <p className="text-blue font-weight-bold">Kies hieronder de leerpaden die je interesse hebben:</p>
                                    {!state.editPathsData && (<>
                                    {(!state.profileData?.paths || state.profileData?.paths.length==0) && (<p className="text-blue">Nog geen leerpaden gekozen</p>)}
                                    {state.profileData?.paths?.map((item, key)=>(
                                        <p className="p-1 m-0" key={key}><strong>-</strong> {item}</p>
                                    ))}
                                    <button onClick={editPaths} className="main_button mt-2 bg-yellow text-blue">Selecteer</button></>)}

                                    {state.editPathsData && (<>
                                        {pathTypes.map((item, key)=>(
                                            <p className="p-1 m-0 profile-label" key={key}><input checked={state.profileData?.paths?.indexOf(item)>-1} onChange={event => handleCheckboxChange(event.target.value, event.target.name, event.target.checked)} type="checkbox" name="paths" value={item}/><label>{item}</label></p>
                                        ))}
                                        <button onClick={updateProfileHandler} className="main_button bg-yellow text-blue">Opslaan</button></>)}
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-orange text-white  d-inline-block p-4 pl-0 font-weight-bold">Solliciteren</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-lila profile-holder"

                                >
                                    <h4 className="bg-lila text-blue mt-4 p-0 font-weight-bold">Hier kun je je CV uploaden en bewaren.</h4>
                                    <div>
                                        {state.profileData?.cvs && (
                                            state.profileData.cvs.map((item, key)=>(
                                                <p key={key} className="text-blue"><a target="_blank" className="text-blue" href={API_URL + "/cvs/" + item.result}>{getDate(item.time)}</a> - <a className="text-blue" href="#" onClick={(event)=> {if(window.confirm('Weet je zeker dat je dit bestand wilt verwijderen?')) fileDelete("cvs",item.result)}}><i>verwijderen</i></a></p>
                                            ))
                                        )}
                                    </div>
                                    <input type="file" accept="application/pdf" onChange={(event)=> cvFileSelect(event.target)}/><br/>
                                    <br/>
                                    <button onClick={uploadCVFile} className="main_button bg-yellow text-blue">Upload CV</button>

                                </div>
                            </div>

                            <div className="d-none row mt-5">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-orange text-white d-inline-block p-4 pl-0 font-weight-bold">Solliciteren</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-lila"

                                >
                                    <h4 className="bg-lila text-blue mt-4 p-0 font-weight-bold"></h4>
                                    <p className="text-blue font-weight-bold">Upload jouw CV:</p>
                                    <button className="main_button bg-yellow text-blue">Uploaden</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    } else {
        return (
            <div className="tipsTricks container-fluid m-0">
                <div className="row">
                    <div className="headerImage col-12 p-0"></div>
                </div>
                <div className="row bg-cyan angle tipsHeader">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-lightblue text-white d-inline-block p-4 pl-0 font-weight-bold">Inloggen</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-purple form-holder"

                                >
                                    <div className="rightInfo">
                                        <span>Nog geen account?</span>
                                        <Link to="/register">Registreren</Link>
                                    </div>
                                    <Form
                                        method="POST"
                                        noValidate
                                        validated={state.validate}
                                        onSubmit={(event) => handleLogin(event)}
                                    >
                                        <Form.Group controlId="username">
                                            <Form.Label>E-mailadres</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required

                                                onChange={event => handleUsername(event.target.value)}
                                                value={state.username}
                                            />
                                            <Form.Control.Feedback type="invalid">E-mailadres kan niet leeg
                                                zijn.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="password">
                                            <Form.Label>Wachtwoord</Form.Label>
                                            <Form.Control
                                                type="password"
                                                required

                                                onChange={event => handlePassword(event.target.value)}
                                                value={state.password}
                                            />
                                            <Form.Control.Feedback type="invalid">Wachtwoord kan niet leeg
                                                zijn.</Form.Control.Feedback>
                                        </Form.Group>
                                        <span className="formError">{state.error}</span>
                                        <Button className="button bg-orange" type="submit">
                                            Inloggen
                                        </Button>
                                    </Form>
                                    <div className="rightInfo">
                                        <Link to="/resetpassword">Wachtwoord vergeten?</Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
