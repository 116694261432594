import React from "react";
import { NavLink } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { NavItem, NavDropdown } from "react-bootstrap";
import { NavDropdownMenu } from "react-bootstrap-submenu";
import { LinkContainer } from "react-router-bootstrap";
import FullPageSpinner from "../FullPageSpinner";
/**
 * List of pages in navbar
 */
const GET_PAGES_QUERY = gql`
  query MENU_ITEMS($first: Int, $location: MenuLocationEnum) {
    menuItems(first: $first, where: { location: $location }) {
      nodes {
        key: id
        parentId
        title: label
        url
        path
        cssClasses
      }
    }
  }
`;

export default function PageList(props) {
  const { loading, error, data } = useQuery(GET_PAGES_QUERY, {
    variables: { location: props.menu, first: 100 },
  });

  if (loading) return <FullPageSpinner disableMargin={true} />;
  if (error) return <p>Error :(</p>;

  const flatListToHierarchical = (
    data = [],
    { idKey = "key", parentKey = "parentId", childrenKey = "children" } = {}
  ) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
      const newItem = { ...item };
      const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
      childrenOf[id] = childrenOf[id] || [];
      newItem[childrenKey] = childrenOf[id];
      parentId
        ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
        : tree.push(newItem);
    });
    return tree;
  };

  let menuItems = flatListToHierarchical(data.menuItems.nodes);


  return menuItems.map((item, key) => {
    if (typeof item.children !== "undefined" && item.children.length > 0) {
      return (
        <React.Fragment  key={key}>
          <NavDropdownMenu
            key={key}
            title={item.title}
            id="collasible-nav-dropdown"
            className="font-weight-bold"

          >
            {item.children.map((navItem, index) => {
              return (
                <LinkContainer
                  key={index}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to={navItem.path
                    .replace("lessonpath", "leerpad")
                    .replace("/job", "/beroep")
                    .replace("/beroepoverview", "/joboverview")}
                >
                  <NavDropdown.Item className={navItem.cssClasses}>{navItem.title}</NavDropdown.Item>
                </LinkContainer>
              );
            })}
          </NavDropdownMenu>
        </React.Fragment>
      );
    } else {
      return (
        <NavItem className="nav-item" key={key}>
          <NavLink
            className={"nav-link " + item.cssClasses}
            key={item.key}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            to={item.path
              .replace("lessonpath", "leerpad")
              .replace("/job", "/beroep")}
          >
            {item.title}
          </NavLink>
        </NavItem>
      );
    }
  });
}
