import React from "react";
import {Link, useParams} from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import NotFound from "./NotFound";
import FullPageSpinner from "../components/FullPageSpinner";
import LearnPathBlock from "../components/LearnPathBlock";
import Title from "../components/Title";

/**
 * Jobs Page
 */

const GET_PAGE_QUERY = gql`
  query GetJobOverview($uri: ID!) {
    joboverview(id: $uri, idType: URI) {
      id
      databaseId
      title
      content
      date
      uri
      jobOverviewFields {
        fieldGroupName
        jobblocks {
          jobTitle
          jobInfo
          jobLink
          learnPathLink
        }
      }
    }
  }
`;

export default function JoboverviewPage() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_PAGE_QUERY, {
    variables: { uri: slug??'/alle-beroepen' },
  }); /**/

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;

  if (!data.joboverview) {
    return <NotFound />;
  }

  return (
    <div className="joboverview container-fluid m-0">
        <Title pageTitle={data.joboverview.title}/>
      <div className="row">
        <div className="headerImage col-12 p-0 pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>{data.joboverview.title}</h1>
                    </div>
                    <div
                        className="col-12 pt-5 pb-5"
                        dangerouslySetInnerHTML={{ __html: data.joboverview.content }}
                    ></div>
                </div>
            </div>
        </div>
      </div>
      <div className="row bg-yellow angle partnerHeader">
        <div className="col-12">
          <div className="container">
            <div className="row flex-row">
                {data.joboverview.jobOverviewFields.jobblocks.map((item, key) => (
                    <div className={"col-12 col-md-4  flex-column"}>
                        <div className="partnerBlock">
                            <div className={"imageHolder p-4 bg" + key%4}>
                                <h3>{item.jobTitle}</h3>
                            </div>
                            <div className="blockContent p-4">
                                <div dangerouslySetInnerHTML={{__html:item.jobInfo}}/>
                            </div>
                            <div className="buttonHolder pb-4 px-4">
                                <Link to={item.jobLink}>
                                    <button className="bg-orange px-4 px-md-5">Naar het beroep</button>
                                </Link>
                                <Link to={item.learnPathLink}>
                                    <button className="bg-blue px-4 px-md-5">Naar het leerpad</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
