import React, {useState} from "react";
import WebinarForm from "../WebinarForm";
import LearnPathInfo from "./LearPathInfo";

export default function PagePathBLock(props) {
  let stepLength = props.content.steps?.length??1;
  let colWidth = Math.min(12, Math.floor(12 / stepLength));

  if (stepLength === 4) {
    colWidth = 6;
  }

  const [state, setState] = useState({
    showModal: false,
    item: null
  });

  function showModalHandler(item, service) {
    setState({ showModal: true, item: item});
  }

  function hideModalHandler() {
    setState({ showModal: false, item: null});
  }

  return (
      <>
        <LearnPathInfo
            showModal={state.showModal}
            item={state.item}
            hideModalHandler={hideModalHandler}
        />
    <div className={"col-12 m-0 p-0 bg-block-" + (props.theKey % 4)}>
      <div className={"learnPathBlock mb-5 p-3"}>
        <h3><a id={props.content.title.replace(/[^A-Za-z]/g,'')}></a>{props.content.title}</h3>
        <div className="container">
          <div className="row p-5">
                <p dangerouslySetInnerHTML={{__html:props.content.text}} />
          </div>
        </div>
      </div>
    </div>
        </>
  );
}
