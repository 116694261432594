import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Page from "./../../pages/Page";
import JobPage from "../../pages/JobPage";
import LearnPathPage from "../../pages/LearnPathPage";
import HomePage from "../../pages/HomePage";
import TestPage from "../../pages/TestPage";
import FaqPage from "../../pages/FaqPage";
import CalendarPage from "../../pages/CalendarPage";
import TipsTricksPage from "../../pages/TipsTricksPage";
import PartnersPage from "../../pages/Partners";
import JoboverviewPage from "../../pages/JobOverview";
import LandingPage from "../../pages/LandingPage";
import LoginPage from "../../pages/LoginPage";
import RegisterPage from "../../pages/RegisterPage";
import SetPasswordPage from "../../pages/SetPasswordPage";
import ResetPassword from "../../pages/ResetPassword";

export default class AppRouter extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/faq" component={FaqPage} />
        <Route path="/test" component={TestPage} />
        <Route path="/webevents/:slug?" component={CalendarPage} />
        <Route path="/beroep/:slug" component={JobPage} />
        <Route path="/leerpad/:slug" component={LearnPathPage} />
          <Route path="/tips-tricks/:slug?" component={TipsTricksPage} />
          <Route path="/partners/:slug?" component={PartnersPage} />
          <Route path="/beroepen/:slug?" component={JoboverviewPage} />
          <Route path="/specialisaties/:slug?" component={LandingPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/profile" component={LoginPage} />
          <Route path="/register" component={RegisterPage}  />
          <Route path="/setpassword/:slug?" component={SetPasswordPage}  />
          <Route path="/resetpassword" component={ResetPassword}  />
        <Route path="/:slug" component={Page} />
      </Switch>
    );
  }
}
