import React from "react";
import { gql, useQuery } from "@apollo/client";
import FullPageSpinner from "../FullPageSpinner";
import JobButton from "../JobButton";
import UserIcon from "../../assets/user_login.svg";
import UserLoggedInIcon from "../../assets/user_loggedin.svg";
import {Link, useParams} from "react-router-dom";
import {AUTH_TOKEN} from "../../env";

function logout()
{
    localStorage.removeItem(AUTH_TOKEN);
    window.location.reload();
}

export default function UserLogin(props) {

    let firstnameInit = props.user?.firstName?.substring(0,1);
    let lastnameInit = props.user?.lastName?.substring(0,1);

    return (
        <li className="nav-item user-login dropdown depth0">
            {firstnameInit && (<><Link className="nav-link dropdown-toggle" onClick={props.hideMenu} to="/profile"><strong>{firstnameInit}{lastnameInit}</strong> <img className="userIcon" src={UserLoggedInIcon}/></Link>
                <label
                htmlFor="logoutuser" className="mobile-toggle d-lg-none">&nbsp;</label><input
                type="checkbox" className="d-none" id="logoutuser"/>
                <ul className="dropdown-menu">
                <li className="dropdown-item"><a className="nav-link " onClick={logout} href="#">Uitloggen</a></li>
                </ul></>

            )}
            {!firstnameInit && (<><Link className="nav-link dropdown-toggle" onClick={props.hideMenu} to="/login"><img className="userIcon" src={UserIcon}/></Link>
                <label
                    htmlFor="logoutuser" className="mobile-toggle d-lg-none">&nbsp;</label><input
                    type="checkbox" className="d-none" id="logoutuser"/>
                <ul className="dropdown-menu">
                    <li className="dropdown-item"><a className="nav-link " onClick={props.hideMenu} href="/login">Inloggen</a></li>
                </ul></>)}
        </li>
    );
}
