import React, { Component, Link } from "react";
import facebookLogo from "./assets/facebook.png";
import linkedinLogo from "./assets/linkedin.png";

export default class Footer extends Component {
  render() {
    return (
      <>
        <footer className="angle">
          <div className="hornHolder d-none d-md-block"></div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-4 offset-sm-2">
                <div className="footerContent">
                  <h4>Contact</h4>
                  <p>
                    Grote Voort 291
                    <br />
                    8041 BL Zwolle
                    <br/>
                    <a className="text-black" href="mailto:info@werkinhetvooruitzicht.nl?subject=Wihv: Contact via de website">info@werkinhetvooruitzicht.nl</a>
                    <br/>
                    <a className="text-black" href="tel:+31885253505">+31 88 525 3505</a>

                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-4 text-right">
                <div className="footerContent">
                  <h4>Volg ons</h4>
                  <a
                    href="https://www.linkedin.com/company/werkinhetvooruitzicht/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={linkedinLogo} alt="LinkedIn" className="icon" />
                  </a>
                </div>
              </div>
            </div>
            <div className="row mt-5 mt-md-4">
              <div className="col-12 offset-md-2 col-md-8">
                <div className="row">
                  <div className="col-6 offset-3 offset-md-0 col-md-3 footerLogo my-auto text-center">
                    <a href="https://www.itph-academy.nl/" target="_blank">
                      <img
                          className={"mw"}
                        src="/images/logostrim/itph.png"
                        alt="Logo ITPH Academy"
                      />
                    </a>
                  </div>
                  <div className="col-6 offset-3 offset-md-0 col-md-3 footerLogo my-auto text-center">
                    <a href="https://www.microsoft.com/nl-nl" target="_blank">
                      <img className="smallw" src="/images/logostrim/microsoft.png" alt="Logo Microsoft" />
                    </a>
                  </div>
                  <div className="col-6 offset-3 offset-md-0 col-md-3 footerLogo my-auto text-center">
                    <a href="https://www.linkedin.com/" target="_blank">
                      <img
                          className="smallw"
                        src="/images/logostrim/linkedin.png"
                        alt="Logo LinkedIn Learning"
                      />
                    </a>
                  </div>
                  <div className="col-6 offset-3 offset-md-0 col-md-3 footerLogo my-auto text-center">
                    <a href="https://www.github.com/" target="_blank">
                      <img
                          className="smallw"
                          src="/images/logostrim/github.png"
                          alt="Logo GitHub"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <p className="text-center">
                  <a className="text-white mr-3" target="_blank" href="/documents/privacy.pdf">
                    Privacy statement
                  </a>
                  <a
                    className="text-white"
                    target="_blank"
                    href="/documents/gebruiksvoorwaarden.pdf"
                  >
                    Gebruiksvoorwaarden
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="yellowLines left"></div>
        </footer>
      </>
    );
  }
}
