import React from "react";
import { gql, useQuery } from "@apollo/client";
import FullPageSpinner from "../FullPageSpinner";
import JobButton from "../JobButton";

const GET_JOBS_QUERY = gql`
  query GetJobs {
    jobs {
      edges {
        node {
          title
          slug
          link
        }
      }
    }
  }
`;

export default function HomePageJobBlock(props) {
  const { loading, error, data } = useQuery(GET_JOBS_QUERY, {
    variables: { uri: "/" },
  });

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;
  return (
    <div className="row custom-button-container">
      {data.jobs.edges.map((item, key) => (
        <JobButton key={key} to={"/beroep/" + item.node.slug} className={"button-" + key}>
          {item.node.title}
        </JobButton>
      ))}
    </div>
  );
}
