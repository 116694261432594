import React, {useState} from "react";


export default function CustomFormField(props) {

    let field = '';

    //console.log(props.content);

    if(props.content.type === 'text')
    {
        field = (
            <input name={props.content.label} type="text" required={(props.content.required?true:false)} placeholder={props.content.label + (props.content.required?' (verplicht)':'')}/>
        );
    } else if(props.content.type === 'email')
    {
        field = (
            <input name={props.content.label} type="email" required={(props.content.required?true:false)} placeholder={props.content.label + (props.content.required?' (verplicht)':'')}/>
        );
    } else if(props.content.type === 'checkbox')
    {
        field = (
            <><input name={props.content.label} required={(props.content.required?true:false)} type="checkbox" id={'checkbox' + props.theKey} value="1"/> <label for={'checkbox' + props.theKey}>{props.content.label}</label></>
        );
    } else if(props.content.type === 'textarea')
    {
        field = (
            <textarea name={props.content.label} required={(props.content.required?true:false)} placeholder={props.content.label + (props.content.required?' (verplicht)':'')}/>
        );
    }

    return (
        <>
            <div className="customFormField">
                {field}
            </div>
        </>
    );
}
