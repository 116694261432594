import React from "react";
import { WP_JSON_API_URL } from "../../env";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/nl";

class VideoModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <>
                <Modal
                    show={this.props.showModal}
                    onHide={this.props.hideModalHandler}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton className="bg-cyan flex-column">
                        <Modal.Title className={`text-blue font-weight-bold`} as="h2">
                            {this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="videoWrapper">
                            <iframe src="https://player.vimeo.com/video/472365341" width="640" height="360" frameBorder="0"
                                    allow="autoplay; fullscreen" allowFullScreen></iframe>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default VideoModal;
