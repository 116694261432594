import React from "react";
import { WP_JSON_API_URL } from "../../env";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/nl";

class WebinarForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      webinarId: 0,
      startDate: 0,
      endDate: 0,
      stafflist: [],
      firstName: "",
      lastName: "",
      email: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    fetch(`${WP_JSON_API_URL}/wihv/v1/sign-up-owa`, {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then(function (response) {
      //console.log(response);
      return response.json();
    });

    event.preventDefault();

    this.props.hideModalHandler();
  };

  handleChange = (event) => {

    this.setState({
      [event.target.name]: event.target.value,
      webinarId: this.props.webinar.book_id,
      stafflist: this.props.webinar.staff_list,
      startDate: this.props.webinar.start_date,
      endDate: this.props.webinar.end_date,
    });
  };

  render() {

    return (
      <>
        <Modal
          show={this.props.showModal}
          onHide={this.props.hideModalHandler}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-cyan flex-column">
            <Modal.Title className={`text-blue font-weight-bold`} as="h1">
              {this.props.webinar?.title.substr(this.props.webinar.title.indexOf('- ')+1).trim() ?? ""}
            </Modal.Title>
            <h5 className={`text-pink font-weight-bold`}>
              {this.props.webinar?.title.substr(0,this.props.webinar.title.indexOf('- ')) ?? ""}
            </h5>
            <h6 className={`mb-0 font-weight-bold text-white`}>
              <Moment
                local
                locale="nl"
                unix
                format="D MMMM YYYY [|] H:mm [uur]"
              >
                {this.props.webinar?.start_date}
              </Moment>
            </h6>
            <p className="text-white mt-2"><i>{this.props.webinar?.title.substr(0,this.props.webinar.title.indexOf(' - ')).toLowerCase() === 'webinar' ?"Let op! Tijdens de webinar is jouw camera en naam zichtbaar voor anderen. Wil je dit niet? Gebruik dan bij de aanmelding een nickname en zet jouw camera uit.": ""}</i></p>
          </Modal.Header>
          <Modal.Body>
            {this.props.service?.description && (
              <p dangerouslySetInnerHTML={{__html:this.props.service.description}}></p>
            )}
            <form onSubmit={this.handleSubmit}>
              <div className="form-group required">
                <label>Voornaam</label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"

                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group required">
                <label htmlFor="last_name">Achternaam</label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"

                  onChange={this.handleChange}
                  required
                />
              </div>
              <div className="form-group required">
                <label htmlFor="email">E-mailadres</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"

                  onChange={this.handleChange}
                  required
                />
              </div>
              <button
                type="submit"
                className="btn btn-rounded btn-orange w-100"
              >
                Aanmelden
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default WebinarForm;
