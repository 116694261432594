import React from "react";
import {Link, useParams} from "react-router-dom";
import {useQuery, gql} from "@apollo/client";
import JobBlock from "../components/JobBlock";
import NotFound from "./NotFound";
import FullPageSpinner from "../components/FullPageSpinner";
import Title from "../components/Title";

/**
 * Jobs Page
 */
const GET_PAGE_QUERY = gql`
  query GetJob($uri: ID!) {
    job(id: $uri, idType: URI) {
      id
      databaseId
      title
      content
      date
      uri
      acfFields {
        fieldGroupName
        blocks {
          blockTitle
          blockText
        }
        titleIcon {
            sourceUrl
        }
        largeBlockTitle
        largeBlockText
        skillBlocks {
            skillTitle
            skillText
        }
        buttonText
        buttonLink
        backButtonText
        backButtonLink
      }
    }
  }
`;

export default function JobPage() {
    const {slug} = useParams();

    const {loading, error, data} = useQuery(GET_PAGE_QUERY, {
        variables: {uri: slug},
    }); /**/

    if (loading) return <FullPageSpinner/>;
    if (error) return <p>Error :(</p>;

    //console.log(data.job.acfFields.blocks);

    if (!data.job) {
        return <NotFound/>;
    }

    let button = "";
    let backButton = "";

    if (data.job.acfFields.buttonLink && data.job.acfFields.buttonText) {
        button = /^https?:\/\//.test(data.job.acfFields.buttonLink) ? (
            <a
                href={data.job.acfFields.buttonLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                <button className="jobButtonRight  px-4 px-md-5">{data.job.acfFields.buttonText}</button>
            </a>

        ) : (
            <Link to={data.job.acfFields.buttonLink}>
                <button className="jobButtonRight  px-4 px-md-5">{data.job.acfFields.buttonText}</button>
            </Link>

        );
    }

    if (data.job.acfFields.backButtonLink && data.job.acfFields.backButtonText) {
        backButton = /^https?:\/\//.test(data.job.acfFields.buttonLink) ? (
            <a
                href={data.job.acfFields.backButtonLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                <button className="jobButtonLeft  px-4 px-md-5">{data.job.acfFields.backButtonText}</button>
            </a>
        ) : (
            <Link to={data.job.acfFields.backButtonLink}>
                <button className="jobButtonLeft px-4 px-md-5">{data.job.acfFields.backButtonText}</button>
            </Link>
        );
    }

    return (
        <div className="job container-fluid m-0">
            <Title pageTitle={data.job.title}/>
            <div className="row">
                <div className="headerImage col-12 p-0">
                    <img src="/images/job_header.jpg" alt={data.job.title}/>
                </div>
            </div>
            <div className="row bg-purple angle jobHeader">
                <div className="col-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-10">
                                <h1>{data.job.title}</h1>
                            </div>
                            <div className="col-2">
                                {data.job.acfFields.titleIcon && (<img className="rightImage" src={data.job.acfFields.titleIcon.sourceUrl}/>)}
                            </div>
                            <div
                                className="col-12 pt-5"
                                dangerouslySetInnerHTML={{__html: data.job.content}}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="container jobContainer">
                        <div className="row">
                            {data.job.acfFields.blocks.map((item, key) => (
                                <JobBlock
                                    key={key}
                                    id={key}
                                    title={item.blockTitle}
                                    text={item.blockText}
                                    className="bg-yellow"
                                />
                            ))}
                        </div>
                        {data.job.acfFields.largeBlockTitle && (
                            <div className="row">
                                <div className="col-12 mb-5">
                                    <div className="jobBlock w-100 bg-cyan p-3 p-md-5">
                                        <h3>{data.job.acfFields.largeBlockTitle}</h3>
                                        <span className="text-white" dangerouslySetInnerHTML={{__html:data.job.acfFields.largeBlockText}}></span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-12">
                    <div className="container">
                        <div className="row">
                            {data.job.acfFields.skillBlocks.map((item, key) => (
                                <div className="col-12 col-md-6 col-lg-4 skillBlock mb-5">
                                    <h3>{item.skillTitle}</h3>
                                    <div className="content px-2">
                                        <p dangerouslySetInnerHTML={{__html:item.skillText}}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 mt-5 text-center text-md-left">
                                {backButton}
                            </div>
                            <div className="col-12 col-md-6 mt-5 text-center text-md-right">
                                {button}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
