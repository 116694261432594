import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRouter from "./components/AppRouter";
import {BrowserRouter as Router, useHistory, useLocation} from "react-router-dom";
import ReactGA from "react-ga";
import CookieConsent from "./components/CookieConsent";
import {gql, useQuery} from "@apollo/client";
import FullPageSpinner from "./components/FullPageSpinner";
import {AUTH_TOKEN} from "./env";
import historyHashObserver from "./components/AppRouter/historyHashObserver";

function usePageViews() {
    const history = useHistory();

    historyHashObserver(history, 3000);
  let location = useLocation();
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [location]);
}

const GET_VIEWER_QUERY = gql`
query getViewer {
    viewer {
      id
      userId
      username
      firstName
      lastName
      userFields {
            fieldGroupName
            profieldata
            nieuwsbrief
            adres
            postcode
            woonplaats
            telefoonnummer
        }
    }
  }`;

export function PageViews() {
  usePageViews();
  return <span />;
}

function App() {

  const trackingId = "UA-178050794-1"; // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId);

    const { loading, error, data } = useQuery(GET_VIEWER_QUERY);

    if (loading) return <FullPageSpinner />;
    if (error) {
        localStorage.removeItem(AUTH_TOKEN);
    }

    //console.log(data);
    if(data && data.viewer)
        window.userdata = data.viewer;

  return (
    <Router>
      <CookieConsent>
        Door het gebruik van cookies kunnen wij het gebruik van onze website
        optimaliseren, zien hoe bezoekers zoals jij de website gebruiken en op
        basis daarvan de website verbeteren. Cookies voor het tonen van
        advertenties worden voor deze website niet aangewend en automatisch
        verwijderd.
      </CookieConsent>
      <Header />
      <AppRouter />
      <PageViews />
      <Footer />
    </Router>
  );
}

export default App;
