import React from "react";
import { useQuery, gql } from "@apollo/client";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";
import FullPageSpinner from "./../components/FullPageSpinner";
import JobBlock from "../components/JobBlock";
import JobButton from "./../components/JobButton";
import LearnPathBlock from "../components/LearnPathBlock";
import PagePathBLock from "../components/PagePathBlock";
import Title from "../components/Title";
import CustomForm from "../components/Form";
import LandingBlock from "../components/PagePathBlock/LandingBlock";

/**
 * Default Page
 */
const GET_PAGE_QUERY = gql`
  query GET_PAGE_BY_URI($uri: ID!) {
    page(id: $uri, idType: URI) {
      id
      databaseId
      title
      date
      uri
      content
      pageFields {
        title
        titleFontsize
        subtitle
        layoutType
        blocks {
          title
          text
          icon {
            sourceUrl
          }
          link
          linkTitle
        }
        jobBlocks {
          link {
            target
            title
            url
          }
        }
        formActive
        formGroup {
            formTitle
            formText
            formThankYouText
            formFields {
                label
                type
                required
            }
            submitButtonText
           }
      }
    }
  }
`;

export default function Page() {
  const location = useLocation();

  const { loading, error, data } = useQuery(GET_PAGE_QUERY, {
    variables: { uri: location.pathname },
  });

  if (loading) return <FullPageSpinner />;
  if (error || !data.page) return <NotFound />;

  //console.log(data.page);

  if(data.page.pageFields.layoutType === 'jobs') {
    return (
        <>
            <Title pageTitle={data.page.title}/>
          <div className="container-fluid m-0 home">
            <div className="row pt-4 bg-pink">
              <div className="col-sm-7 offset-sm-1">
                {data.page.pageFields.subtitle && (
                    <h3>{data.page.pageFields.subtitle}</h3>
                )}
                <h1 className={data.page.pageFields.titleFontsize}
                    dangerouslySetInnerHTML={{__html: data.page.pageFields.title}}
                />
                <div
                    className="textHolder mt-5"
                    dangerouslySetInnerHTML={{
                      __html: data.page.content,
                    }}
                />
              </div>
              <div className="col-sm-3 text-center my-auto">
                <img
                    src="/images/monitor.png"
                    alt={data.page.pageFields.title}
                    className={`img-fluid`}
                />
              </div>
            </div>
          </div>
          {data.page.pageFields.blocks && (
              <div className="bg-purple">
                <div className="container jobContainer">
                  <div className="row pt-5">
                    {data.page.pageFields.blocks.map((item, key) => (
                        <JobBlock
                            key={key}
                            id={key}
                            title={item.title}
                            text={item.text}
                            className="bg-yellow"
                        />
                    ))}
                  </div>
                </div>
              </div>
          )}
          {data.page.pageFields.jobBlocks && (
              <div className="container my-3">
                <div className="row custom-button-container">
                  {data.page.pageFields.jobBlocks.map((item, key) => (
                      <JobButton
                          key={key}
                          to={item.link.url}
                          className={"button-" + key}
                      >
                        {item.link.title}
                      </JobButton>
                  ))}
                </div>
              </div>
          )}
        </>
    );
  } else if(data.page.pageFields.layoutType === 'path'){
    return (
        <div className="learnPathPage container-fluid m-0">
          <div className="row">
            <div className="headerImage col-12 p-0"></div>
          </div>
          <div className="row bg-pink angle learnPathHeader">
            <div className="col-12">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h1 dangerouslySetInnerHTML={{ __html: data.page.pageFields.title}}/>
                  </div>
                  <div
                      className="col-12 pt-5 pb-5"
                      dangerouslySetInnerHTML={{ __html: data.page.content }}
                  ></div>
                </div>
              </div>
            </div>
              {data.page.pageFields.formActive && (
                  <div className="col-12">
                      <div className="container">
                          <div className="row">
                              <div className="col-12 p-0">
                                  <CustomForm dataid={data.page.databaseId} content={data.page.pageFields.formGroup}/>
                              </div>
                          </div>
                      </div>
                  </div>
              )}
          </div>
            {data.page.pageFields?.blocks && (
          <div className="row">
            <div className="col-12 mt-5">
              <div className="container learnPathContainer">
                <div className="row">
                  {data.page.pageFields?.blocks?.map((item, key) => (
                      <PagePathBLock key={key} theKey={key} content={item} />
                  ))}
                </div>
              </div>
            </div>
          </div>)}
        </div>
    );
  } else {
      return (
          <div className="landing container-fluid m-0">
              <div className="row">
                  <div className="headerImage col-12 p-0 learnPathHeader">
                      <div className="container mt-5 pb-5">
                          <div className="row">
                              <div className="col-8 mt-5 pb-5">
                                  <h1 dangerouslySetInnerHTML={{ __html: data.page.pageFields.title}}/>
                                  <h3 dangerouslySetInnerHTML={{ __html: data.page.pageFields.subtitle}}/>
                              </div>
                              <div className="col-4 col-md-3 text-center my-auto">
                                  <img
                                      src="/images/monitor.png"
                                      className={`img-fluid`}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row bg-pink angle pt-5">
                  <div className="col-12">
                      <div className="container">

                                  {data.page.pageFields?.blocks && (
                                      <div className="row">
                                          <div className="col-12 mt-5">
                                              <div className="container learnPathContainer">

                                                      {data.page.pageFields?.blocks?.map((item, key) => (
                                                          <LandingBlock key={key} theKey={key} content={item} />
                                                      ))}

                                              </div>
                                          </div>
                                      </div>)}
                              </div>

                  </div>
              </div>

          </div>
      );
  }
}
