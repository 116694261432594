import React from "react";
import { WP_JSON_API_URL } from "../../env";
import {Form, Modal} from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/nl";
import {ApolloClient, gql, useMutation} from "@apollo/client";
import {withApollo} from "@apollo/client/react/hoc";
import {Link, useParams} from "react-router-dom";

const REGISTER_USER = gql`
  mutation RegisterUser($username: String! $firstname: String! $lastname: String! $nieuwsbrief: Int!) {
    registerUser(input: {
      clientMutationId: "uniqueId"
      username: $username
      email: $username
      firstName: $firstname
      lastName: $lastname
      voorwaarden: 1
      nieuwsbrief: $nieuwsbrief
    }) {
      user {
        id
        userId
        name
        firstName
        jwtAuthToken
        jwtRefreshToken
      }
    }
  }
`

class TestModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            newsletter: false
        };

       // this.register = useMutation(REGISTER_USER);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event) => {


        event.preventDefault();
        //const [register, {data}] = useMutation(REGISTER_USER);

        this.props.client.mutate({
            mutation: REGISTER_USER,
            variables: { username:this.state.email, firstname:this.state.firstName, lastname:this.state.lastName, nieuwsbrief:this.state.newsletter?1:0 }
        })
            .then(response => this.props.hideModalHandler())
            .catch(err => this.props.hideModalHandler());

    };

    handleTerms = (checked) => {

    }

    handleNewsletter = (checked) => {
        this.setState({
            newsletter: checked
        });
    }

    handleChange = (event) => {

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {

        return (
            <>
                <Modal
                    show={this.props.showModal}
                    backdrop="static"
                    keyboard={false}
                    className="test-modal"
                >
                    <Modal.Header className=" flex-column p-0 m-0 z-top">
                        <Modal.Title className={`text-white bg-cyan d-inline-block p-4 pl-0 font-weight-bold`} as="h3">
                            Ik wil de uitslag van de<br/>beroepskeuzetest inzien
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="form-holder mt-n5 ">

                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group required">
                                <label>Voornaam</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="firstName"

                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group required">
                                <label htmlFor="last_name">Achternaam</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="lastName"

                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group required">
                                <label htmlFor="email">E-mailadres</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    name="email"

                                    onChange={this.handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group required">
                            <input
                                type="checkbox"
                                required
                                className="form-control"
                                onChange={event => this.handleTerms(event.target.checked)}

                            /><span class="checkLabel text-blue">Ik ga akkoord met de <a className="text-cyan" target="_blank" href="/documents/gebruiksvoorwaarden.pdf" target="_blank">gebruiksvoorwaarden</a> en het <a target="_blank" className="text-cyan" href="/documents/privacy.pdf" target="_blank">privacy statement</a>.</span>
                            </div>
                            <div className="form-group">
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    onChange={event => this.handleNewsletter(event.target.checked)}

                                /><span className="checkLabel text-blue">Ik wil de nieuwsbrief ontvangen.</span>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                            <button
                                type="submit"
                                className="btn btn-rounded btn-orange w-100 d-inline-block"
                            >
                                Bekijk nu de uitslag
                            </button></div>
                                <div className="col-12 col-md-6">
                            <Link to="/"><button
                                className="btn btn-rounded btn-orange mt-3 mt-md-0 w-100 d-inline-block"
                            >
                                Terug naar home
                            </button></Link>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default withApollo(TestModal);
