import React from "react";
import {Link, useParams} from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import NotFound from "./NotFound";
import FullPageSpinner from "../components/FullPageSpinner";
import LearnPathBlock from "../components/LearnPathBlock";
import Title from "../components/Title";

/**
 * Jobs Page
 */

const GET_PAGE_QUERY = gql`
  query GetPartners($uri: ID!) {
    partner(id: $uri, idType: URI) {
      id
      databaseId
      title
      content
      date
      uri
      partnerFields {
        fieldGroupName
        partners {
          title
          text
          logo {
            sourceUrl
          }
          logoLink
        }
      }
    }
  }
`;

export default function PartnersPage() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_PAGE_QUERY, {
    variables: { uri: slug??'/partners' },
  }); /**/

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;

  if (!data.partner) {
    return <NotFound />;
  }

  return (
    <div className="partners container-fluid m-0">
        <Title pageTitle={data.partner.title}/>
      <div className="row">
        <div className="headerImage col-12 p-0 pt-5 pb-5">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>{data.partner.title}</h1>
                    </div>
                    <div
                        className="col-12 pt-5 pb-5"
                        dangerouslySetInnerHTML={{ __html: data.partner.content }}
                    ></div>
                </div>
            </div>
        </div>
      </div>
      <div className="row bg-pink angle partnerHeader">
        <div className="col-12">
          <div className="container">
            <div className="row container">
                {data.partner.partnerFields.partners.map((item, key) => (
                    <div className={"col-12 col-md-4 partnerBlock p-0 my-2"}>
                        <div className="innerBlock  m-2">
                        <div className="imageHolder">
                            {item.logoLink && (<a href={item.logoLink} target="_blank"><img src={item.logo.sourceUrl}/></a>)}
                            {!item.logoLink && (<img src={item.logo.sourceUrl}/>)}
                                </div>
                        <div className="blockContent p-4">
                        <h3>{item.title}</h3>
                        <div dangerouslySetInnerHTML={{__html:item.text}}/>
                        </div>
                        </div>
                    </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
