import React from "react";
import { Link } from "react-router-dom";

/**
 * Not Found Page
 */
export default function NotFound() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <h1>Error 404 &mdash; Niet gevonden</h1>
          <hr />
          <p>
            Deze pagina waar je naar opzoek was bestaat niet (meer). Ga terug
            naar de <Link to="/">homepagina</Link>.
          </p>
        </div>
      </div>
    </div>
  );
}
