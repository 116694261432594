import React, {useState} from "react";
import WebinarForm from "../WebinarForm";
import LearnPathInfo from "./LearPathInfo";
import {Link} from "react-router-dom";

export default function LandingBlock(props) {
  let stepLength = props.content.steps?.length??1;
  let colWidth = Math.min(12, Math.floor(12 / stepLength));

  if (stepLength === 4) {
    colWidth = 6;
  }

  const [state, setState] = useState({
    showModal: false,
    item: null
  });

  function showModalHandler(item, service) {
    setState({ showModal: true, item: item});
  }

  function hideModalHandler() {
    setState({ showModal: false, item: null});
  }

  return (
      <>
        <LearnPathInfo
            showModal={state.showModal}
            item={state.item}
            hideModalHandler={hideModalHandler}
        />
    <div className={"row"}>
    <div className={"m-0 p-0 bg-block-" + (props.theKey % 4) + (props.content?.icon?' col-12 col-md-7':' col-12') + ((props.theKey % 2 == 0)?' order-first':' order-last')}>
      <div className={"learnPathBlock mb-5 p-3"}>
        <h3><a id={props.content.title.replace(/[^A-Za-z]/g,'')}></a>{props.content.title}</h3>
        <div className="container">
          <div className="row p-5">
                <p dangerouslySetInnerHTML={{__html:props.content.text}} />
            {props.content.link && (<Link to={props.content.link} className={"blockLink"}>{props.content.linkTitle}</Link>)}
          </div>
        </div>
      </div>
    </div>

        {props.content?.icon && (
            <div className={"col-md-3 d-none d-md-flex p-md-2 p-lg-5 text-center my-auto"}>
              <div className={"p-3"}>
              <img src={props.content.icon.sourceUrl} className={"img-fluid"}/>
              </div>
            </div>
        )}
    </div>
        </>
  );
}
