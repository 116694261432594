import React, {useState} from "react";
import WebinarForm from "../WebinarForm";
import LearnPathInfo from "./LearPathInfo";
import infoIcon from "../../assets/info.svg";

export default function LearnPathBlock(props) {
  let stepLength = props.content.steps?.length??1;
  let colWidth = Math.min(12, Math.floor(12 / stepLength));

  if (stepLength === 4) {
    colWidth = 6;
  }

  const [state, setState] = useState({
    showModal: false,
    item: null
  });

  function showModalHandler(item, service) {
    setState({ showModal: true, item: item});
  }

  function hideModalHandler() {
    setState({ showModal: false, item: null});
  }

  return (
      <>
        <LearnPathInfo
            showModal={state.showModal}
            item={state.item}
            hideModalHandler={hideModalHandler}
        />
    <div className={"col-12 m-0 p-0 bg-block-" + (props.theKey % 4)}>
      <div className={"learnPathBlock mb-5 p-3"}>
        <h3><a id={props.content.blockTitle.replace(/[^A-Za-z]/g,'')}></a>{props.content.blockTitle}</h3>
        <div className="container">
          <div className="row p-5">
            {props.content.steps?.length > 0 && props.content.steps.map((item, key) => (
              <div
                className={`buttonSpace mt-5 mt-md-0 col-md-${colWidth} ${colWidth === 6 ? "mt-md-5" : ""}`}
                key={key}
              >
                <h5>
                  <b>{item.stepTitle}</b>
                  {item.stepInfoText && item.stepInfoText.trim()!==''
                      ?
                      (
                          <a
                              style={{cursor:'pointer'}}
                              onClick={() =>
                                  showModalHandler(item)
                              }
                          >
                          <img style={{marginLeft:12, width:24}} src={infoIcon} alt="Extra informatie"/>
                          </a>
                      ):''
                  }
                </h5>
                <p>
                  {item.bullets
                    ? item.bullets.map((bitem, bkey) => (
                        <span className="d-block" key={bkey}>
                          {bitem.bullet}
                        </span>
                      ))
                    : ""}
                </p>
                <a
                  href={item.buttonLink}
                  target={item.buttonLink.indexOf('ttp')>0?"_blank":""}
                  rel="noopener noreferrer"
                >
                  <button className="stepButton">{item.buttonTitle}</button>
                </a>
              </div>
            ))}
          </div>
        </div>
        {props.content.textBelowBlock && (
          <div
            className="mt-3 inner-text-black"
            dangerouslySetInnerHTML={{ __html: props.content.textBelowBlock }}
          ></div>
        )}
      </div>
    </div>
        </>
  );
}
