import React from "react";
import { useQuery, gql } from "@apollo/client";
import Faq from "react-faq-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Title from "../components/Title";

/**
 * FAQ Page
 */
const GET_PAGES_QUERY = gql`
  query GET_PAGE_BY_URI($uri: ID!) {
    page(id: $uri, idType: URI) {
      id
      databaseId
      title
      date
      uri
      acfFields {
        fieldGroupName
        title
        subtitle
      }
      faq {
        questions {
          question
          answer
        }
      }
    }
  }
`;

export default function FaqPage() {
  const { loading, error, data } = useQuery(GET_PAGES_QUERY, {
    variables: { uri: "/faq" },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const faqData = data.page.faq.questions.map(({ question, answer }) => ({
    title: question,
    content: answer,
  }));

  //console.log(faqData);

  return (
    <div className="container-fluid m-0 home">
      <Title pageTitle={data.page.title}/>
      <div className="row pt-4 bg-pink">
        <div className="col-sm-7 offset-sm-1">
          <h3>{data.page.acfFields.subtitle}</h3>
          <h1 dangerouslySetInnerHTML={{ __html: data.page.acfFields.title }} />
          <Faq
            data={{ rows: faqData }}
            styles={{ bgColor: "transparent" }}
            config={{
              animate: true,
              arrowIcon: <FontAwesomeIcon icon={faChevronDown} />,
            }}
          />
        </div>
        <div className="col-sm-3 text-center my-auto">
          <img
            src="/images/monitor.png"
            alt={data.page.acfFields.title}
            className={`img-fluid`}
          />
        </div>
      </div>
    </div>
  );
}
