import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import PageList from "../PageList";
import wihvLogo from "../../assets/wihvlogo.svg";
import PageListNew from "../PageList/PageListNew";
import {AUTH_TOKEN} from "../../env";
import UserLogin from "../Users/UserLogin";

export default class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isNavExpanded: false
    };

    this.setIsNavExpanded = (isNavExpanded) => {
      this.setState({ isNavExpanded: isNavExpanded });
    }

    this.handleClick = (e) => {
      if (this.node.contains(e.target)) {
        // if clicked inside menu do something
      } else {
        // If clicked outside menu, close the navbar.
        this.setState({ isNavExpanded: false });
      }
    }

    this.hideNavBar = this.hideNavBar.bind(this);
  }

  hideNavBar() {
    this.setState({ isNavExpanded: false });
    let items = document.querySelectorAll('input.d-none');
    for (let i = 0; i < items.length; i++) {
      items[i].checked = false;
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  render() {

    let user = window.userdata;

    return (
        <div ref={node => this.node = node} className="top-navbar-container px-1 px-md-5 sticky-top">
        <Navbar collapseOnSelect expand="lg" onToggle={this.setIsNavExpanded}
                expanded={this.state.isNavExpanded}>
          <Navbar.Brand href="/">
            <div className="logo"><img src={wihvLogo} alt="Werk in het vooruitzicht logo"/></div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <PageListNew user={user} hideMenu={this.hideNavBar} menu="PRIMARY" />
              <UserLogin user={user} hideMenu={this.hideNavBar}/>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      </div>
    );
  }
}
/*
 <!--  -->
 */

/*
<nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="/"><div className="logo"><img src={wihvLogo} alt="Werk in het vooruitzicht logo"/></div></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="ml-auto navbar-nav">
              <PageListNew menu="PRIMARY"/>
            </ul>
          </div>
        </nav>
 */