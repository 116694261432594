import React from "react";
import { WP_JSON_API_URL } from "../../env";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import "moment/locale/nl";

class LearnPathInfo extends React.Component {
    constructor(props) {
        super(props);


       // this.handleChange = this.handleChange.bind(this);
    }

    render() {

        return (
            <>
                <Modal
                    show={this.props.showModal}
                    onHide={this.props.hideModalHandler}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton className="bg-cyan flex-column">
                        <Modal.Title className={`text-blue font-weight-bold`} as="h1">
                            {this.props.item?.stepTitle ?? ""}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.item?.stepInfoText && (
                            <p dangerouslySetInnerHTML={{__html:this.props.item.stepInfoText}}/>
                        )}

                            <button
                                onClick={this.props.hideModalHandler}
                                className="btn btn-rounded btn-orange w-100"
                            >
                                Sluiten
                            </button>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default LearnPathInfo;
