import React, { Component } from "react";

export default class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookieConsent: localStorage.getItem("cookieConsent") ?? false,
    };
  }

  buttonPressed = () => {
    localStorage.setItem("cookieConsent", true);

    this.setState({
      cookieConsent: true,
    });
  };

  render() {
    return (
      <div
        className={`position-fixed cookie-consent-container w-100 ${
          !this.state.cookieConsent ? "" : "d-none"
        }`}
      >
        <div className="cookie-consent bg-yellow p-3">
          <div className="row justify-content-end">
            <div className="col-sm-12 col-md-8">{this.props.children}</div>
            <div className="col-sm-12 col-md-4 text-md-center mt-2 mt-md-0">
              <a className="btn btn-primary" onClick={this.buttonPressed}>
                Akkoord
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
