import React from "react";
import { Link } from "react-router-dom";

export default function JobButton(props) {
  return (
    <div className={`col-md-6 p-2`}>
      <Link to={props.to}>
        <button className={props.className}>{props.children}</button>
      </Link>
    </div>
  );
}
