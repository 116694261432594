import React from "react";
import ReactDOM from "react-dom";
import {AUTH_TOKEN, GRAPH_API_URL} from "./env";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";

import "./main.scss";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {setContext} from "@apollo/client/link/context";


const httpLink = createHttpLink({
    uri: GRAPH_API_URL,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(AUTH_TOKEN);
    let auth = false;

    // return the headers to the context so httpLink can read them
    try {
        auth = JSON.parse(token);
    } catch (e) {

    }

    return {
        headers: {
            ...headers,
            authorization: auth ? `Bearer ${auth.authToken}` : "",
        }
    }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
