import React from "react";

export default function JobBlock(props) {
  return (
    <div className={`col-12 col-lg-6 mb-5`}>
      <div
        className={`jobBlock ${props.className} p-3 p-md-5 ${
          props.id % 2 === 0 ? "mr-lg-2" : "ml-lg-2"
        }`}
      >
          <h3><a id={props.title.replace(/[^A-Za-z]/g,'')}></a>{props.title}</h3>
        <span dangerouslySetInnerHTML={{ __html: props.text }} />
      </div>
    </div>
  );
}
