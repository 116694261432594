import React from "react";
import {Link, useParams} from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import NotFound from "./NotFound";
import FullPageSpinner from "../components/FullPageSpinner";
import LearnPathBlock from "../components/LearnPathBlock";
import CustomForm from "../components/Form";
import EventBlock from "../components/EventBlock";
import Title from "../components/Title";

/**
 * Jobs Page
 */

const GET_PAGE_QUERY = gql`
  query GetTipsTrick($uri: ID!) {
    tipstrick(id: $uri, idType: URI) {
      id
      databaseId
      title
      content
      date
      uri
      tipsTricksFields {
        fieldGroupName
        tipsTricks {
          title
          text
          buttonText
          buttonLink
          fieldGroupName
          events {
              event {
                __typename
                ... on Webevent {
                  id 
                  webEventFields {
                    eventTitle
                    eventType
                    eventSummary
                    eventText
                    eventDates {
                      eventDate
                    }
                  }
                  
                }
              }
            }
            logos {
                title
              logo {
                sourceUrl
              }
              logoLink
              }
        }
        backButtonText
        backButtonLink
        forwardButtonText
        forwardButtonLink
        formActive
        formGroup {
            formTitle
            formText
            formThankYouText
            formFields {
                label
                type
                required
            }
            submitButtonText
           }
      }
    }
  }
`;

export default function TipsTricksPage() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_PAGE_QUERY, {
    variables: { uri: slug??'/tips-tricks' },
  }); /**/

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;

  if (!data.tipstrick) {
    return <NotFound />;
  }

  let button = "";
  let backButton = "";

  if (data.tipstrick.tipsTricksFields.forwardButtonLink && data.tipstrick.tipsTricksFields.forwardButtonText) {
    button = /^https?:\/\//.test(data.tipstrick.tipsTricksFields.forwardButtonLink) ? (
        <a
            href={data.tipstrick.tipsTricksFields.forwardButtonLink}
            target="_blank"
            rel="noopener noreferrer"
        >
          <button className="jobButtonRight  px-4 px-md-5">{data.tipstrick.tipsTricksFields.forwardButtonText}</button>
        </a>

    ) : (
        <Link to={data.tipstrick.tipsTricksFields.forwardButtonLink}>
          <button className="jobButtonRight  px-4 px-md-5">{data.tipstrick.tipsTricksFields.forwardButtonText}</button>
        </Link>

    );
  }

  if (data.tipstrick.tipsTricksFields.backButtonLink && data.tipstrick.tipsTricksFields.backButtonText) {
    backButton = /^https?:\/\//.test(data.tipstrick.tipsTricksFields.backButtonLink) ? (
        <a
            href={data.tipstrick.tipsTricksFields.backButtonLink}
            target="_blank"
            rel="noopener noreferrer"
        >
          <button className="jobButtonLeft  px-4 px-md-5">{data.tipstrick.tipsTricksFields.backButtonText}</button>
        </a>
    ) : (
        <Link to={data.tipstrick.tipsTricksFields.backButtonLink}>
          <button className="jobButtonLeft px-4 px-md-5">{data.tipstrick.tipsTricksFields.backButtonText}</button>
        </Link>
    );
  }

  return (
    <div className="tipsTricks container-fluid m-0">
        <Title pageTitle={data.tipstrick.title}/>
      <div className="row">
        <div className="headerImage col-12 p-0"></div>
      </div>
      <div className="row bg-yellow angle tipsHeader">
        <div className="col-12">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>{data.tipstrick.title}</h1>
              </div>
              <div
                className="col-12 pt-5 pb-5"
                dangerouslySetInnerHTML={{ __html: data.tipstrick.content }}
              ></div>

                {data.tipstrick.tipsTricksFields.tipsTricks.map((item, key) => (
                    <div className={"col-12 mt-5 tip tip" + key%4}>
                    <h3>{item.title}</h3>
                      <div dangerouslySetInnerHTML={{__html:item.text}}/>

                            <div className="container">
                                <div className="row">
                                    {item.events?.map((ev, index) => (
                                        <EventBlock event={ev.event} theKey={index} key={index}/>
                                    ))
                                    }
                                </div>
                            </div>
                                <div className="container partnerHeader">
                                    <div className="row container">
                                        {item.logos?.map((logoItem, logoKey) => (
                                            <div className={"col-12 col-md-4 partnerBlock p-0 my-2"}>
                                                <div className="innerBlock  m-2">
                                                    <div className="imageHolder">
                                                        {logoItem.logoLink && (<a href={logoItem.logoLink} target="_blank"><img src={logoItem.logo.sourceUrl}/></a>)}
                                                        {!logoItem.logoLink && (<img src={logoItem.logo.sourceUrl}/>)}
                                                    </div>
                                                    {logoItem.title && (<div className="blockContent  p-4">
                                                        <p className="p-0 m-0 text-smaller ">{logoItem.title}</p>
                                                    </div>)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>


                        {item.buttonText && (
                            /^https?:\/\//.test(item.buttonLink) ? (
                                <a href={item.buttonLink} target="_blank">
                                    <button className="">{item.buttonText}</button>
                                </a>) : (
                                <Link to={item.buttonLink}>
                                    <button className="">{item.buttonText}</button>
                                </Link>
                            )
                        )}
                    </div>
                ))}
                {data.tipstrick.tipsTricksFields.formActive && (
                    <CustomForm dataid={data.tipstrick.databaseId} content={data.tipstrick.tipsTricksFields.formGroup}/>
                )}
                    <div className="col-12 col-md-6 mt-5 text-center text-md-left pt-5">
                      {backButton}
                    </div>
                    <div className="col-12 col-md-6 mt-5 text-center text-md-right pt-5">
                      {button}
                    </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
