import React, {useState} from "react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import HomePageJobBlock from "../components/HomePageJobBlock";
import FullPageSpinner from "../components/FullPageSpinner";
import TestModal from "../components/TestModal";
import VideoModal from "../components/VideoModal";
import EventBlock from "../components/EventBlock";
import {useCookies} from "react-cookie";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

/**
 * Home Page
 */
const GET_PAGES_QUERY = gql`
  query GET_PAGE_BY_URI($uri: ID!) {
    page(id: $uri, idType: URI) {
      id
      databaseId
      title
      date
      uri
      acfFields {
        fieldGroupName
        subtitle
        title
        videoPopupTitel
        firstText
        firstImage {
          sourceUrl
          altText
        }
        secondImage {
          sourceUrl
          altText
        }
        ctaTitle
        ctaButtonText
        ctaLink
        ctaImages {
          image {
            sourceUrl
            altText
          }
        }
        secondText
        eventButtonTitle
        eventButtonLink
        events {
              event {
                __typename
                ... on Webevent {
                  id 
                  webEventFields {
                    eventTitle
                    eventType
                    eventSummary
                    eventText
                    eventDates {
                      eventDate
                    }
                  }
                  
                }
              }
            }
      }
    }
  }
`;

export default function HomePage() {
  const { loading, error, data } = useQuery(GET_PAGES_QUERY, {
    variables: { uri: "/" },
  });

  const [cookies, setCookie] = useCookies(['seen_video']);

  const [state, setState] = useState({
    showModal: cookies?.seen_video != 1
  });


  function showModalHandler(item, service) {
    setState({ showModal: true});
  }

  function hideModalHandler() {
    setState({ showModal: false})

    let expires = new Date()
    expires.setTime(expires.getTime() + (1000 * 60 * 60 * 2));
    setCookie('seen_video',1, {path: '/', expires});
  }

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;

  const pictures = [
    "0.png",
    "1.png",
    "2_2.png",
    "3.png",
    "4.png",
    "5.png",
    "6_2.png",
    "7.png",
  ];

  const pictures2 = [
    "0.png",
    "1.png",
    "2.png",
    "3.png",
    "4.png",
    "5.png",
    "6_2.png",
    "7.png",
  ];

  const randomPicture1 = pictures[Math.floor(Math.random() * pictures.length)];
  pictures2.splice(pictures.indexOf(randomPicture1), 1);
  const randomPicture2 = pictures2[Math.floor(Math.random() * pictures2.length)];

  console.log(cookies?.seen_video);

  function transform(node) {

    if (node.type === 'tag' && node.name === 'a') {
      return <Link to={node.attribs.href}>{node.children[0].data}</Link>
    }
  }

  return (
      <>
        <VideoModal
            showModal={state.showModal}
            hideModalHandler={hideModalHandler}
            title={data.page.acfFields.videoPopupTitel}
        />
    <div className="container-fluid m-0 home">
      <div className="row pt-4 bg-pink">
        <div className="col-sm-7 offset-sm-1">
          <h3>{data.page.acfFields.subtitle}</h3>
          <h1 dangerouslySetInnerHTML={{ __html: data.page.acfFields.title }} />
        </div>
        <div className="col-sm-3 text-center my-auto">
          <img
            src="/images/monitor.png"
            alt={data.page.acfFields.title}
            className={`img-fluid`}
          />
        </div>
      </div>
      <div className="row pt-4 pb-5 bg-purple secondHolder">
        <img
          alt="Illustration of woman"
          className="img-fluid position-absolute d-none d-lg-block"
          src={"images/random-images/" + randomPicture1}
        ></img>
        <div className="container">
          <div className="row">
            <div className="d-none d-lg-block col-md-6"></div>
            <div className="col-sm-12 col-lg-6">
              <div
                className="textHolder"

              >{ ReactHtmlParser(data.page.acfFields.firstText, {transform:transform}) }</div>
              <Link to={data.page.acfFields.ctaLink}>
                <button
                    className="jobButton"
                    dangerouslySetInnerHTML={{
                      __html: data.page.acfFields.ctaButtonText,
                    }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-5 pb-5 bg-yellow thirdHolder">
        <div className="col-12 text-center">
          <h2
            className="textHolder"
            dangerouslySetInnerHTML={{ __html: data.page.acfFields.ctaTitle }}
          />
        </div>
        <div className="col-12 text-left mt-5">
          <div className="container">
            <div className="row">
              {data.page.acfFields.events?.map((ev, index) => (<>
                  {ev.event && (<EventBlock event={ev.event} theKey={index} key={index}/>)}
                  </>
              ))
              }
            </div>
          </div>
        </div>
        {data.page.acfFields.eventButtonTitle && (<div className="col-12 text-center">
          <Link to={data.page.acfFields.eventButtonLink}>
            <button
                className="jobButton bg-cyan"
                dangerouslySetInnerHTML={{
                  __html: data.page.acfFields.eventButtonTitle,
                }}
            />
          </Link>
        </div>)}
        <div className="col-12 mt-5 text-center image-container">
          <div className="container">
            <div className="row">
              {data.page.acfFields.ctaImages.map((ctaImage, index) => {
                return (
                  <div key={index} className="col-sm-12 col-md-4 mb-3 mb-md-0">
                    <img
                      alt="CTA"
                      className="img-fluid"
                      key={index}
                      src={ctaImage.image.sourceUrl}
                    ></img>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-5 pb-5 bg-lila fourthHolder position-relative">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div
                className="textHolder"
                dangerouslySetInnerHTML={{
                  __html: data.page.acfFields.secondText,
                }}
              />
            </div>
          </div>
        </div>
        <img
          alt="Man illustration"
          className="img-fluid position-absolute d-none d-lg-block"
          src={"images/random-images/" + randomPicture2}
        ></img>
      </div>
      <div className="row pt-5 pb-5 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <HomePageJobBlock />
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
  );
}
