import React from "react";
import IframeResizer from 'iframe-resizer-react'

/**
 * Iframe
 */
export default class CustomIframe extends React.Component {
  componentDidMount() {
    //this.refs.iframe.addEventListener("load", this.props.onLoad);

  }

  render() {
    return <IframeResizer {...this.props} />;
  }
}
