import React, {useState} from "react";
import {useQuery, gql, useMutation} from "@apollo/client";
import {AUTH_TOKEN} from "../env";
import { Form, Button, Alert } from 'react-bootstrap';
import EventBlock from "../components/EventBlock";
import {Link} from "react-router-dom";
import CustomForm from "../components/Form";
import FullPageSpinner from "../components/FullPageSpinner";
import NotFound from "./NotFound";
//import gql from "graphql-tag/src";
/**
 * Home Page
 */
const REGISTER_USER = gql`
  mutation RegisterUser($username: String! $firstname: String! $lastname: String! $nieuwsbrief: Int! $voorwaarden: Int!) {
    registerUser(input: {
      clientMutationId: "uniqueId"
      username: $username
      email: $username
      firstName: $firstname
      lastName: $lastname
      nieuwsbrief: $nieuwsbrief
      voorwaarden: $voorwaarden
    }) {
      user {
        id
        userId
        name
        firstName
        jwtAuthToken
        jwtRefreshToken
      }
    }
  }
`
const THANKS_QUERY = gql`
query GetWihvOptionsPage {
    wihvOptionsPage {
        wihvOptions {
            bedankttitel
            bedanktekst
            link
            knoptitel
        }
    }
}`

export default function RegisterPage() {


    const [state, setState] = useState({
        validate: false,
        username: '',
        password: '',
        error: '',
        firstname:'',
        lastname:'',
        lastnameprefix:'',
        emailaddress:'',
        terms:false,
        newsletter:false,
        showThankYou: false
    });

    //if (login) return <p>Loading...</p>;
    //if (error) return <p>Error :(</p>;

    const [register, {data}] = useMutation(REGISTER_USER);

    const { loading, error, data:pagedata } = useQuery(THANKS_QUERY, {});


    if (loading) return <FullPageSpinner />;
    if (error || !pagedata.wihvOptionsPage) return <NotFound />;


    function handleLogin(event) {
        event.preventDefault();


        if(state.terms) {
            const {username, password, emailaddress, firstname, lastname, newsletter, terms} = state;

            //setState({ validate: true });

            //console.log(username);
            let json = JSON.stringify(state);
            // console.log(json);

            register({variables: {username, password, firstname, lastname, nieuwsbrief: newsletter?1:0, voorwaarden: terms?1:0}})
                .then(response => handleLoginSuccess(response))
                .catch(err => handleLoginFail(err.graphQLErrors[0].message));
        } else {
            setState(prevState => ({ ...prevState, error: "Je dient akkoord te gaan met de voorwaarden." }));
        }
    }

    function handleLoginSuccess(response) {
        //window.location.href='/login';
        setState(prevState => ({ ...prevState, showThankYou: true }));
    }

    function handleLoginFail( err) {
        //console.log(err);
        const error = err.split('_').join(' ').toUpperCase();

        setState(prevState => ({ ...prevState, error: error }));

    }

    function handleUsername(username) {
        //console.log(username);
        setState(prevState => ({ ...prevState, username: username }));
    }

    function handleFirstname(username) {
        //console.log(username);
        setState(prevState => ({ ...prevState, firstname: username }));
    }

    function handleLastname(username) {
        //console.log(username);
        setState(prevState => ({ ...prevState, lastname: username }));
    }

    function handleLastnameprefix(username) {
       // console.log(username);
        setState(prevState => ({ ...prevState, lastnameprefix: username }));
    }

    function handlePassword(password) {
        setState(prevState => ({ ...prevState, password: password }));
    }

    function handleTerms(terms) {
        //console.log(terms);
        setState(prevState => ({ ...prevState, terms: terms }));
    }

    function handleNewsletter(terms) {
        //console.log(terms);
        setState(prevState => ({ ...prevState, newsletter: terms }));
    }

    function renderMessage(loading, error) {
        if (error) {
            return (
                <div variant="danger">
                    {this.state.error}
                </div>
            )
        } else if (loading) {
            return (
                <div variant="primary">
                    Loading...
                </div>
            )
        }
    }

    if(state.showThankYou)
    {
       return ( <div className="tipsTricks container-fluid m-0">
            <div className="row">
                <div className="headerImage col-12 p-0"></div>
            </div>
            <div className="row bg-cyan angle tipsHeader">
                <div className="col-12">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ml-0 pl-0 z-top">
                                <h3 className="bg-lightblue text-white d-inline-block p-4 pl-0 font-weight-bold">{pagedata.wihvOptionsPage.wihvOptions.bedankttitel}</h3>
                            </div>
                            <div
                                className="col-12 p-5 mt-n5 bg-purple"

                            >
                            <p className="text-white white-p pt-5 link-white" dangerouslySetInnerHTML={{__html:pagedata.wihvOptionsPage.wihvOptions.bedanktekst}}></p>

                                <Link to={pagedata.wihvOptionsPage.wihvOptions.link}><button className="main_button bg-orange text-white">{pagedata.wihvOptionsPage.wihvOptions.knoptitel}</button></Link>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>);
    } else {
        return (
            <div className="tipsTricks container-fluid m-0">
                <div className="row">
                    <div className="headerImage col-12 p-0"></div>
                </div>
                <div className="row bg-cyan angle tipsHeader">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-lightblue text-white d-inline-block p-4 pl-0 font-weight-bold">Aanmelden</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-purple form-holder"

                                >
                                    <div className="rightInfo">
                                        <span>Heb je al een account?</span>
                                        <Link to="/login">Inloggen</Link>
                                    </div>
                                    <Form
                                        method="POST"
                                        noValidate
                                        validated={state.validate}
                                        onSubmit={(event) => handleLogin(event)}
                                    >
                                        <Form.Group controlId="firstname">
                                            <Form.Label>Voornaam</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                onChange={event => handleFirstname(event.target.value)}
                                                value={state.firstname}
                                            />
                                            <Form.Control.Feedback type="invalid">Voornaam kan niet leeg
                                                zijn.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="lastname">
                                            <Form.Label>Achternaam</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                onChange={event => handleLastname(event.target.value)}
                                                value={state.lastname}
                                            />
                                            <Form.Control.Feedback type="invalid">Achternaam kan niet leeg
                                                zijn.</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="username">
                                            <Form.Label>E-mailadres</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required

                                                onChange={event => handleUsername(event.target.value)}
                                                value={state.username}
                                            />
                                            <Form.Control.Feedback type="invalid">E-mailadres kan niet leeg
                                                zijn.</Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group controlId="terms">
                                            <Form.Control
                                                type="checkbox"
                                                required

                                                onChange={event => handleTerms(event.target.checked)}

                                            /><span className="checkLabel">Ik ga akkoord met de <a className="text-blue" target="_blank" href="/documents/gebruiksvoorwaarden.pdf" target="_blank">gebruiksvoorwaarden</a> en het <a target="_blank" className="text-blue" href="/documents/privacy.pdf" target="_blank">privacy statement</a>.</span>

                                        </Form.Group>
                                        <Form.Group controlId="newsletter">
                                            <Form.Control
                                                type="checkbox"
                                                required

                                                onChange={event => handleNewsletter(event.target.checked)}

                                            /><span className="checkLabel">Ik wil graag de nieuwsbrief ontvangen.</span>

                                        </Form.Group>

                                        <span className="formError" dangerouslySetInnerHTML={{__html:state.error}}></span>
                                        <Button className="button bg-orange" type="submit">
                                            Registreren
                                        </Button>
                                    </Form>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
