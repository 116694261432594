import React, { useState } from "react";
import useFetch from "use-http";
import FullPageSpinner from "../components/FullPageSpinner";
import WebinarForm from "../components/WebinarForm";
import Moment from "react-moment";
import "moment/locale/nl";
import { WP_JSON_API_URL } from "../env";
import {useParams} from "react-router-dom";

/**
 * Calendar Page
 */
export default function CalendarPage() {

  const { slug } = useParams();

  let typeFilter = [];

  if(typeof slug !== 'undefined' && slug.length > 3)
  {
    let fc = slug.substr(0,1);
    let lc = slug.slice(-1);

    let filterString = '';

    if(fc === '[' && lc === ']') //possible filter
    {
      filterString = slug.substr(1, slug.length-2);
      let filterParts = filterString.split(';');

      filterParts.forEach((item, key) => {
        if(item.indexOf(':') > 0)
        {
          let filterName = item.substr(0, item.indexOf(':'));
          let filterItems = item.substr(item.indexOf(':')+1, item.length).split(',').map((item, key)=>item.toLowerCase());

          if(filterName.toLowerCase() === 'type')
          {
            typeFilter = filterItems;
          }
        }
      });

    }
  }



  const [state, setState] = useState({
    showModal: false,
    webinar: null,
    service: null,
  });

  const { loading, error, data = [] } = useFetch(
    `${WP_JSON_API_URL}/wihv/v1/calendar`,
    {},
    []
  );

  const {
    loading: loadingServices,
    error: servicesError,
    data: servicesData = [],
  } = useFetch(`${WP_JSON_API_URL}/wihv/v1/services`, {}, []);

  function showModalHandler(item, service) {
    setState({ showModal: true, webinar: item, service: service });
  }

  function hideModalHandler() {
    setState({ showModal: false, webinar: null, service: null });
  }

  if (loading || loadingServices) return <FullPageSpinner />;
  if (error || servicesError) return <p>Error :(</p>;

  function getServiceById(serviceId) {
    return servicesData.data.find((service) => service.id === serviceId);
  }
// / {typeFilter?.length === 0 || typeFilter.indexOf(item.title.substr(0,item.title.indexOf('- ')).toLowerCase())>-1 ? (
  const _this = this;

  return (
    <>
      <WebinarForm
        showModal={state.showModal}
        webinar={state.webinar}
        service={state.service}
        hideModalHandler={hideModalHandler}
      />
      <div className="container-fluid m-0 home">
        <div className="row pt-4 bg-pink">
          <div className="col-12 text-center">
            <h3>Webevents</h3>
          </div>
          <div  className="col-12 text-center text-orange"><p><b>Hier vind je alle interactieve ondersteuning. Kies hieronder welk event je gaat bijwonen en schrijf je direct in voor één of meerdere sessies!
<br/>
            Per aanmelding ontvang je een bevestigingsmail met meer informatie over je deelname aan het event.</b></p></div>
        </div>
      </div>
      <div className={`container-fluid bg-yellow`}>
        <div className={`container`}>
          <div className={`row py-5`}>
            {data.data.map((function(item, key) {

              if(typeFilter.length === 0 || typeFilter.indexOf(item.title.substr(0,item.title.indexOf(' - ')).toLowerCase())>-1) {
                return (

                    <div key={key} className={`col-sm-12 col-md-6 col-lg-4 mb-5`}>
                      <div className={`jobBlock bg-white mr-lg-2`}>
                        <div className={`bg-cyan py-3 px-4 py-md-5 blockHeader`}>
                          <h2 className={`text-blue font-weight-bold h2-smaller`}>
                            {item.title.substr(item.title.indexOf('- ') + 1).trim()}
                          </h2>
                          <h5 className={`text-pink font-weight-bold`}>
                            {item.title.substr(0, item.title.indexOf(' - '))}
                          </h5>
                          <p className={`mb-0 text-white font-weight-bold`}>
                            <Moment
                                local
                                locale="nl"
                                unix
                                format="D MMMM YYYY [|] H:mm [uur]"
                            >
                              {item.start_date}
                            </Moment>
                          </p>
                        </div>
                        <div className={`py-3 px-4 py-md-4 blockContent`}>
                          <p className="mb-0" dangerouslySetInnerHTML={{__html:getServiceById(item.service_id).description}}>

                          </p>
                          <a
                              role="button"
                              className={`text-blue`}
                              onClick={() =>
                                  showModalHandler(item, getServiceById(item.service_id))
                              }
                          ><i>Meer informatie</i></a>
                          <a
                              className={`btn btn-rounded btn-orange mt-3 w-100`}
                              onClick={() =>
                                  showModalHandler(item, getServiceById(item.service_id))
                              }
                          >
                            Aanmelden
                          </a>
                        </div>
                      </div>
                    </div>
                )
              }
            }
            ).bind(this))}
          </div>
        </div>
      </div>
    </>
  );
}
