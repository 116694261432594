import React, {useState} from "react";
import {useQuery, gql, useMutation} from "@apollo/client";
import {AUTH_TOKEN, WP_JSON_API_URL} from "../env";
import { Form, Button, Alert } from 'react-bootstrap';
import EventBlock from "../components/EventBlock";
import {Link, useParams} from "react-router-dom";
import CustomForm from "../components/Form";
//import gql from "graphql-tag/src";
/**
 * Home Page
 */
const REGISTER_USER = gql`
  mutation RegisterUser($username: String! $password: String! $firstname: String! $lastname: String! $json: String!) {
    registerUser(input: {
      clientMutationId: "uniqueId"
      username: $username
      password: $password
      email: $username
      firstName: $firstname
      lastName: $lastname
      description: $json
    }) {
      user {
        id
        userId
        name
        firstName
        jwtAuthToken
        jwtRefreshToken
      }
    }
  }
`

export default function ResetPassword() {
    const {slug} = useParams();

    const [state, setState] = useState({
        email: '',
        error: '',
        send: false
    });



    function handleLogin(event) {
        event.preventDefault();


        fetch(`${WP_JSON_API_URL}/wihv/v1/getpassword`, {
            method: "POST",
            body: JSON.stringify(state),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        }).then(function (response) {
            //window.location.href='/login';
            setState(prevState => ({ ...prevState, send: true }));
        });



        //const {username, password, emailaddress, firstname, lastname} = state;

        //setState({ validate: true });

        //console.log(username);


    }

    function handleLoginSuccess(response) {
        //window.location.href='/login';
        setState(prevState => ({ ...prevState, showThankYou: true }));
    }

    function handleLoginFail( err) {
        //console.log(err);
        const error = err.split('_').join(' ').toUpperCase();

        setState(prevState => ({ ...prevState, error: error }));

    }

    function handlePassword(username) {
        setState(prevState => ({ ...prevState, password: username }));
    }

    function handlePassword2(username) {
        setState(prevState => ({ ...prevState, password2: username }));
    }

    function handleLastname(username) {
       // console.log(username);
        setState(prevState => ({ ...prevState, lastname: username }));
    }

    function handleLastnameprefix(username) {
       // console.log(username);
        setState(prevState => ({ ...prevState, lastnameprefix: username }));
    }

    function handlePassword(password) {
        setState(prevState => ({ ...prevState, email: password }));
    }

    function handleTerms(terms) {
       // console.log(terms);
        setState(prevState => ({ ...prevState, terms: terms }));
    }

    function handleNewsletter(terms) {
       // console.log(terms);
        setState(prevState => ({ ...prevState, newsletter: terms }));
    }

    function renderMessage(loading, error) {
        if (error) {
            return (
                <div variant="danger">
                    {this.state.error}
                </div>
            )
        } else if (loading) {
            return (
                <div variant="primary">
                    Loading...
                </div>
            )
        }
    }

        return (
            <div className="tipsTricks container-fluid m-0">
                <div className="row">
                    <div className="headerImage col-12 p-0"></div>
                </div>
                <div className="row bg-cyan angle tipsHeader">
                    <div className="col-12">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 ml-0 pl-0 z-top">
                                    <h3 className="bg-lightblue text-white d-inline-block p-4 pl-0 font-weight-bold">Reset wachtwoord</h3>
                                </div>
                                <div
                                    className="col-12 p-5 mt-n5 bg-purple form-holder"

                                >
                                    {!state.send && ( <Form
                                        method="POST"
                                        noValidate
                                        validated={state.validate}
                                        onSubmit={(event) => handleLogin(event)}
                                    >
                                        <Form.Group controlId="password">
                                            <Form.Label>E-mailadres</Form.Label>
                                            <Form.Control
                                                type="email"
                                                required
                                                onChange={event => handlePassword(event.target.value)}
                                            />

                                        </Form.Group>
                                        <span className="formError">{state.error}</span>
                                        <Button className="button bg-orange" type="submit">
                                            Verstuur
                                        </Button>
                                    </Form>)}
                                    {state.send && (
                                        <p className="mt-5 mb-5 text-white">Er is een e-mail verstuurd naar het opgegeven emailadres.</p>
                                    )}

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );

}
