import React, {useEffect, useState} from "react";
import FullPageSpinner from "../components/FullPageSpinner";
import CustomIframe from "../components/CustomIframe";
import LearnPathInfo from "../components/LearnPathBlock/LearPathInfo";
import TestModal from "../components/TestModal";
import {AUTH_TOKEN, WP_JSON_API_URL} from "../env";
import Title from "../components/Title";

/**
 * Test Page
 */
export default class TestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true,
      showModal: false,
      item: null
    };
  }

  _iframeLoaded(target) {
    this.setState({
      isLoading: false,
    });

    target.iFrameResizer.resize();
    /*if(event.target.resize)
    {
      console.log("RESIZE!");
      event.target.resize();
    }*/
    //console.log(event.target);

    window.addEventListener('message', (e) => this.messageHandler(e));
    window.test_this = this;
  }

  _iframeInit(event)
  {
    event.iFrameResizer.resize();
  }

  showModalHandler(item, service) {
    const token = localStorage.getItem(AUTH_TOKEN);
    let userJson = false;

    try {
      userJson = JSON.parse(token);
    } catch (e) {};

    let userData = userJson?.user;

    if(!userData)
      window.test_this.setState({ showModal: true, item: item});
  }

  hideModalHandler() {
    window.test_this.setState({ showModal: false, item: null});
  }

   messageHandler(e) {
      if(e.data.jobTestDone)
      {
        //e.data.resultpage;
        /*if(window.userdata && window.userdata.userId) {
          fetch(`${WP_JSON_API_URL}/wihv/v1/saveresults`, {
            method: "POST",
            body: JSON.stringify({result: e.data.resultpage, id: window.userdata.userId}),
            headers: new Headers({
              "Content-Type": "application/json",
            }),
          }).then(function (response) {
            //window.location.href='/login';
          });
        }*/

        this.showModalHandler(null);

      }
  }

  render() {

    return (
        <>
          <Title pageTitle="Beroepskeuzetest"/>
          <TestModal
              showModal={this.state.showModal}
              item={this.state.item}
              hideModalHandler={this.hideModalHandler}
          />
      <div className="container">
        {this.state.isLoading ? <FullPageSpinner /> : null}
        <CustomIframe
          className={`w-100 ${this.state.isLoading ? "d-none" : ""}`}
          onLoad={(event) => this._iframeLoaded(event.target)}
          src="https://test2.werkinhetvooruitzicht.nl/beroepskeuzetest"
          title="Beroepskeuzetest"
          frameBorder="0"
          style={{ height: 800 }}
          checkOrigin={false}
          onInit={(event) => this._iframeInit(event)}
        ></CustomIframe>
      </div>
          </>
    );
  }
}
