import React, {useState} from "react";
import CustomFormField from "./CustomFormField";
import {WP_JSON_API_URL} from "../../env";
import {set} from "react-ga";


export default function CustomForm(props) {

    const [state, setState] = useState({
       submitted: false
    });

    function handleSubmit(event, target)
    {
        event.preventDefault();

        let data = [];

        for(let i=0;i<target.elements.length;i++)
        {
            let e = target.elements.item(i);
            data.push({name:e.name, value:e.value});
        }

        fetch(`${WP_JSON_API_URL}/wihv/v1/submitform`, {
            method: "POST",
            body: JSON.stringify({data:data, id:props.dataid}),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        }).then(function (response) {
            setState((prevState => ({...prevState, submitted:true})));
        });

    }

    return (
        <form className="col-12 customForm mt-5" onSubmit={(event)=>handleSubmit(event, event.target)}>
                <div className="titleHolder p-4">
                    <h3>{props.content.formTitle}</h3>
                </div>
                <div className="textHolder p-4 bg-white">
                    <p dangerouslySetInnerHTML={{__html: props.content.formText}}></p>
                </div>
            {!state.submitted && (<><div className="fieldHolder p-4 bg-white">
                    {props.content.formFields?.map((item, key)=> (
                        <CustomFormField content={item} theKey={key} key={key}/>
                    ))}
                </div>
                <div className="buttonHolder bg-white p-4">
                    <button className="submitButton">{props.content.submitButtonText}</button>
                </div></>)}
            {state.submitted && (<><div className="p-4 bg-white">
                <p dangerouslySetInnerHTML={{__html: (props.content.formThankYouText && props.content.formThankYouText != ''?props.content.formThankYouText:'Bedankt voor je bericht. Wij reageren zo spoedig mogelijk.')}}></p>
                </div></>)}


        </form>
    );
}
