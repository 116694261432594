import React from "react";
import {Link, useParams} from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import NotFound from "./NotFound";
import LearnPathBlock from "../components/LearnPathBlock";
import FullPageSpinner from "../components/FullPageSpinner";
import Title from "../components/Title";

/**
 * Jobs Page
 */

const GET_PAGE_QUERY = gql`
  query GetLandingpages($uri: ID!) {
    landingspage(id: $uri, idType: URI) {
        id
      databaseId
      title
      content
      date
      uri
      acfFields {
        fieldGroupName
        titleIcon {
            sourceUrl
        }
        blocks {
          title
          info
          training {
            title
            info
            infoBlocks {
                title
                info
                buttonText
                buttonLink
            }
          }
        }
        buttonLeftText
        buttonLeftLink
        buttonRightText
        buttonRightLink
      }
    }
  }
`;

export default function LandingPage() {
  const { slug } = useParams();

  const { loading, error, data } = useQuery(GET_PAGE_QUERY, {
    variables: { uri: slug },
  }); /**/

  if (loading) return <FullPageSpinner />;
  if (error) return <p>Error :(</p>;

  if (!data.landingspage) {
    return <NotFound />;
  }


  let leftButton = '';
  let rightButton = '';

  if (data.landingspage.acfFields.buttonLeftLink && data.landingspage.acfFields.buttonLeftText) {
    leftButton = /^https?:\/\//.test(data.landingspage.acfFields.buttonLeftLink) ? (
        <a
            href={data.landingspage.acfFields.buttonLeftLink}
            target="_blank"
            rel="noopener noreferrer"
        >
          <button className="jobButtonRight  px-4 px-md-5">{data.landingspage.acfFields.buttonLeftText}</button>
        </a>

    ) : (
        <Link to={data.landingspage.acfFields.buttonLeftLink}>
          <button className="jobButtonRight  px-4 px-md-5">{data.landingspage.acfFields.buttonLeftText}</button>
        </Link>

    );
  }

  if (data.landingspage.acfFields.buttonRightLink && data.landingspage.acfFields.buttonRightText) {
    rightButton = /^https?:\/\//.test(data.landingspage.acfFields.buttonRightLink) ? (
        <a
            href={data.landingspage.acfFields.buttonRightLink}
            target="_blank"
            rel="noopener noreferrer"
        >
          <button className="jobButtonRight  px-4 px-md-5">{data.landingspage.acfFields.buttonRightText}</button>
        </a>

    ) : (
        <Link to={data.landingspage.acfFields.buttonRightLink}>
          <button className="jobButtonRight  px-4 px-md-5">{data.landingspage.acfFields.buttonRightText}</button>
        </Link>

    );
  }

  return (
    <div className="landingsPage container-fluid m-0">
        <Title pageTitle={data.landingspage.title}/>
      <div className="row">
        <div className="headerImage col-12 p-0"></div>
      </div>
      <div className="row bg-lightblue angle learnPathHeader">
        <div className="col-12">
          <div className="container">
            <div className="row">
                <div className="col-10">
                    <h1>{data.landingspage.title}</h1>
                </div>
                <div className="col-2">
                    {data.landingspage.acfFields.titleIcon && (<img className="titleImage" src={data.landingspage.acfFields.titleIcon.sourceUrl}/>)}
                </div>
              <div
                className="col-12 pt-5 pb-5"
                dangerouslySetInnerHTML={{ __html: data.landingspage.content }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 m-0 p-0 learnPathContainer">
                {data.landingspage.acfFields.blocks.map((item, key) => (
                    <>
                      {item.title && (<div className="container titleContainer mb-5 pb-5">
                            <div className="row">

                                <div className="col-12">
                                    <h2>{item.title}</h2>
                                    <div dangerouslySetInnerHTML={{__html:item.info}}/>
                                </div>
                            </div>
                        </div>)}
                    {item.training.map((training, trainingKey) => (
                        <div className={"col-12 bg" + trainingKey%3 + (item.title?" bgangle"+ trainingKey%3:"")}>
                            <div className={"container mb-5 pb-5"}>
                                <div className="row">
                                    <div className="col-12">
                                        <h3>{training.title}</h3>
                                        <div dangerouslySetInnerHTML={{__html:training.info}}/>
                                    </div>
                                    {training.infoBlocks?.map((infoBlock, infoBlockKey) =>(
                                        <div className="col-12 col-md-4 flex-column">
                                          <div className=" infoBlock h-100 p-0 m-2">
                                            <div className="titleHolder p-3"><h4>{infoBlock.title}</h4></div>
                                            <div className="contentHolder p-3 pt-0" dangerouslySetInnerHTML={{__html:infoBlock.info}}/>
                                            <div className="text-center pb-4">
                                              {/^https?:\/\//.test(infoBlock.buttonLink) ? (
                                                <a href={infoBlock.buttonLink} target="_blank">
                                                    <button className="blockButton">{infoBlock.buttonText}</button>
                                                </a>) : (
                                                  <Link to={infoBlock.buttonLink}>
                                                    <button className="blockButton">{infoBlock.buttonText}</button>
                                                  </Link>
                                                  )}
                                            </div>
                                          </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    </>
                ))}


        </div>
        <div className="col-12">
          <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 m-0 p-0 text-center text-md-left z-top">
              {leftButton}
            </div>
            <div className="col-12 col-md-6 m-0 p-0 text-center text-md-right">
              {rightButton}
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
