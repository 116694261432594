// @flow

/**
 * @param history {@see https://www.npmjs.com/package/history}
 * @param timeout A number of milliseconds to wait for the element to appear after PUSH action has been received.
 */
export default (history: *, timeout: number = 1000) => {
    let observer;
    let timeoutId;
    let initialPageLoad = true;

    if (!window.MutationObserver) {
        return;
    }

    console.log(history);

    const reset = () => {
        if (timeoutId) {
            clearTimeout(timeoutId);

            timeoutId = null;
        }

        if (observer) {
            observer.disconnect();
        }
    };

    const createScrollToElement = (id: string) => {
        id = id.replace(/[^A-Za-z]/g,'');

        return () => {
            const element = document.getElementById(id);
            console.log(element);
            if (element) {
                element.scrollIntoView();

                reset();

                return true;
            }

            return false;
        };
    };

    history.listen((location: *, action: *) => {
        if (timeoutId) {
            reset();
        }

        console.log(location, action);

        if (action !== 'PUSH') {
            //return;
        }

        if (typeof location.hash !== 'string') {
            return;
        }

        const elementId = location.hash.slice(1);

        if (!elementId) {
            return;
        }

        const scrollToElement = createScrollToElement(elementId);

        setTimeout(() => {
            if (scrollToElement()) {
                return;
            }

            observer = new MutationObserver(scrollToElement);

            observer.observe(document, {
                attributes: true,
                childList: true,
                subtree: true
            });

            timeoutId = setTimeout(reset, timeout);
        }, initialPageLoad ? 200 : 1);

        initialPageLoad = false;
    });
};