import React, {useState} from "react";
import Moment from "react-moment";
import WebinarForm from "../WebinarForm";
import EventForm from "../EventForm";


export default function EventBlock(props) {

    const [state, setState] = useState({
        showModal: false,
        webinar: null
    });

    function showModalHandler(item) {
        setState({ showModal: true, webinar: item});
    }

    function hideModalHandler() {
        setState({ showModal: false, webinar: null});
    }

    return (
        <>
            <EventForm
                showModal={state.showModal}
                webinar={state.webinar}
                hideModalHandler={hideModalHandler}
            />
            <div key={props.theKey} className={`col-sm-12 col-md-6 col-lg-4 mb-5`}>
                <div className={`jobBlock bg-white mr-lg-2`}>
                    <div className={`bg-cyan py-3 px-4 py-md-5 blockHeader`}>
                        <h2 className={`text-blue font-weight-bold h2-smaller`}>
                            {props.event.webEventFields.eventTitle}
                        </h2>
                        <h5 className={`text-pink font-weight-bold`}>
                            {props.event.webEventFields.eventType}
                        </h5>
                        <p className={`mb-0 text-white font-weight-bold`}>
                            <Moment
                                local
                                locale="nl"
                                unix
                                format="D MMMM YYYY [|] H:mm [uur]"
                            >
                                {Date.parse(props.event.webEventFields.eventDates.find((ed)=> {
                                    return Date.parse(ed.eventDate) > Date.now();
                                })?.eventDate)/1000}
                            </Moment>
                        </p>
                    </div>
                    <div className={`py-3 px-4 py-md-4 blockContent`}>
                        <p className="mb-0" dangerouslySetInnerHTML={{__html:props.event.webEventFields.eventSummary}}>

                        </p>
                        <a
                            role="button"
                            className={`text-blue`}
                            onClick={() =>
                                showModalHandler(props.event)
                            }
                        ><i>Meer informatie</i></a>
                        <a
                            className={`btn btn-rounded btn-orange mt-3 w-100`}
                            onClick={() =>
                                showModalHandler(props.event)
                            }
                        >
                            Aanmelden
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
